import React from 'react';
import cookie from 'js-cookie';

function Error() {
	return (
		<div className="error-wrapper">
			<h1 className="h1-error-text">
				{ cookie.get('i18next') == 'en' ?
					"Error 404, Page not found."
				:
				"Error 404, Página no encontrada."
				}
			</h1>
		</div>
	)
}

export default Error;
