import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

function Distribuidor({ information }) {
	return (
		<>
			{ information && (
				<div data-aos="fade-down" class="distribuidor-block">
					<div className="dist-info-block">
						<h3>{ information.name }</h3>
						<p>{ information.country }</p>
						<div className="mt-3">
							<div className="modal-distribuitor-information-block">
								<FontAwesomeIcon icon={ faLocationDot } />
								<p>{ information.address }</p>
							</div>

							<div className="modal-distribuitor-information-block">
								<FontAwesomeIcon icon={ faPhone } />
								<p>{ information.phone }</p>
							</div>

							<div className="modal-distribuitor-information-block">
								<FontAwesomeIcon icon={ faEnvelope } />
								<p>{ information.email }</p>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	)
}

export default Distribuidor;
