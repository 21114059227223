import React, { useState } from "react";
import cookie from 'js-cookie';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import config from '../config/config.json';
import Spinner from 'react-bootstrap/Spinner';
import ContactAccordion from "../components/ContactAccordion";
import AlertToastMessages from "../components/AlertToastMessages";
import ReCAPTCHA from "react-google-recaptcha";
import Alert from 'react-bootstrap/Alert';
import { useMediaQuery } from 'react-responsive';

const Contact = () => {
	let captcha;
	document.title = cookie.get('i18next') === 'en' ? "Contact us - Selecta S.A." : "Contacto - Selecta S.A.";
    const isMobileToTablet = useMediaQuery({ minWidth: '20em', maxWidth: '46.24em' });
	const { t } = useTranslation();

	const initialState = {
		firstName: { data: "", error: '' },
		lastName: { data: '', error: '' },
		email: { data: '', error: '' },
		phone: { data: '', error: '' },
		body: { data: '', error: '' }
	}

	const [inputs, setInputs] = useState(initialState);

	const [showToast, setShowToast] = useState(false);
	const [showMessage, setShowMessage] = useState(false);

	const [captchaValidation, setCaptchaValidation] = useState(false);
	const [captchaError, setCaptchaError] = useState("");

	const [variant, setVariant] = useState(null);
	const [message, setMessage] = useState(null);
	const [alertShow, setAlertShow] = useState(false);
	const [checking, setChecking] = useState(false);

	const resetCaptcha = () => {
		captcha.reset();
	}

	const setCaptchaRef = (ref) => {
		if(ref) {
			return captcha = ref;
		}
	}

	function handleNumbers(event) {
		const reg = /^[0-9\b]+$/;

		if(event.target.value === '' || reg.test(event.target.value)) {
			setInputs({ ...inputs, [event.target.name]: { data: event.target.value }});
		}
	}

	function onChangeRecaptcha(value) {
		if(value === null) {
			setCaptchaValidation(false);
		}
		else {
			setCaptchaValidation(true);
			setCaptchaError("");
		}
	}

	const handleInputChange = (event) => {
		const { name, value } = event.target;

		if(value !== '') {
			setInputs(prevState => { return { ...prevState, [name]: { error: '' }}});
		}

		setInputs({ ...inputs, [name] : { data: value }});
	}

	function clearFormReset() {
		setInputs(initialState);
	}

	const handleCloseAlert = (show) => {
		setAlertShow(show);
	}

	const validateFields = () => {
		let ready = false;
		let errorFound = 0;

		if(!captchaValidation) {
			setCaptchaError(cookie.get('i18next') === 'en' ? "Captcha is required" : "Debes pasar validación del captcha.");
			errorFound++;
			setChecking(false);
		}

		if(!inputs.firstName.data) {
			setInputs(prevState => { return { ...prevState, firstName: {
				error: cookie.get('i18next') === 'en' ? "Name field is required" : "Debes completar el campo de Nombre"
			}}});
			errorFound++;
			setChecking(false);
		}

		if(!inputs.lastName.data) {
			setInputs(prevState => { return { ...prevState, lastName: {
				error: cookie.get('i18next') === 'en' ? "Last name field is required" : "Debes completar el campo de Apellido"
			}}});
			errorFound++;
			setChecking(false);
		}

		if(!inputs.email.data) {
			setInputs(prevState => { return { ...prevState, email: {
				error: cookie.get('i18next') === 'en' ? "Email field is required" : "Debes completar el campo de Correo Electrónico"
			}}});
			errorFound++;
			setChecking(false);
		}

		if(!inputs.body.data) {
			setInputs(prevState => { return { ...prevState, body: {
				error: cookie.get('i18next') === 'en' ? "Message body text is required" : "Debes completar el campo de mensaje."
			}}});
			errorFound++;
			setChecking(false);
		}

		if(errorFound <= 0) {
			ready = true;
		}
		return ready;
	}

	const handleSubmit = (e) => {
		let eventForm = e;
		eventForm.preventDefault();
		let ignore = false;
		setChecking(true);

		if(validateFields()) {
			axios.post(config.SERVER_URL + config.POST_CONTACT_FORM, {
				firstName: inputs.firstName.data,
				lastName: inputs.lastName.data,
				email: inputs.email.data,
				body: inputs.body.data,
				telephone: inputs.phone.data,
				country: "N/A",
				})
				.then((response) => {
					if (!ignore) {
						if(response.data.status === "error") {
							setVariant("danger");
							setMessage(response.data.message);
							setAlertShow(true);
							setChecking(false);
						}

						if(response.data.status === "success") {
							setShowToast(true);
							setShowMessage(true);
							setChecking(false);
							clearFormReset();
							resetCaptcha();
						}
					}
				})
				.catch((error) => {
					console.log(error);
				})
				.then(() => {
					ignore = true;
			})
		}
	}

	return (
		<>
            { isMobileToTablet ?
                <img src={
                    cookie.get('i18next') == 'en' ?
                        require('../images/contacto_title_mobile_en.png') :
                        require('../images/contacto_title_mobile.png')
                    } className="imageSection" />
                :
                <img src={
                    cookie.get('i18next') == 'en' ?
                        require('../images/contacto_title_en.png') :
                        require('../images/contacto_title.png')
                    } className="imageSection" />
            }

			<AlertToastMessages show={showToast} setShow={setShowToast} />

			<Alert
				className="alert-block-wrapper"
				show={showMessage}
				onClose={ () => setShowMessage(false) }
				dismissible
				variant="success">
				<Alert.Heading>
					{ cookie.get('i18next') == 'en' ?
						"Thank you for contact us!" :
						"Muchas gracias por contactarnos!"
					}
				</Alert.Heading>
				<p>
					{ cookie.get('i18next') == 'en' ?
						"We will be contacting you via email or phone call as soon as possible."
						:
						"Nos estaremos poniendo en contacto con usted vía email o por teléfono, en la brevedad posible."
					}
				</p>
			</Alert>

			<div className="block-light-spacing bodyContainer bread-content">

				<div className="block-divider">
					<div className="contact-information-wrapper">
						<ContactAccordion />
					</div>

					<div data-aos="fade-left" className="form-contact-wrapper">
						<form
							onSubmit={(e) => { handleSubmit(e) }}
							className="formContact">

							<div className="row mb-4">
								<div className="col-md-6 mb-4">
									<label htmlFor="userName" className="form-label">
										{ cookie.get('i18next') == 'en' ? "Name *" : "Nombre *" }
									</label>
									<input
										type="text"
										className="form-control"
										name="firstName"
										id="name"
										value={ inputs.firstName.data || "" }
										onChange={ handleInputChange }
									/>
									<span className="span-error-message">{ inputs.firstName.error !== '' ? inputs.firstName.error : '' }</span>
								</div>

								<div className="col-md-6 mb-4">
									<label htmlFor="userLastName" className="form-label">
										{ cookie.get('i18next') == 'en' ? "Last Name *" : "Apellido *" }
									</label>
									<input
										type="text"
										className="form-control"
										name="lastName"
										id="lastName"
										value={ inputs.lastName.data || "" }
										onChange={ handleInputChange }
									/>
									<span className="span-error-message">{ inputs.lastName.error !== '' ? inputs.lastName.error : '' }</span>
								</div>
							</div>

							<div className="row mb-4">
								<div className="col-md-6 mb-4">
									<label htmlFor="userEmail" className="form-label">
										{ cookie.get('i18next') == 'en' ? "E-mail *" : "Correo Electrónico *" }
									</label>
									<input
										type="email"
										className="form-control"
										id="userEmail"
										name="email"
										value={ inputs.email.data || '' }
										onChange={ handleInputChange } />

									<span className="span-error-message">{ inputs.email.error !== '' ? inputs.email.error : '' }</span>
								</div>

								<div className="col-md-6 mb-4">
									<label htmlFor="userPhone" className="form-label">
										{ cookie.get('i18next') == 'en' ? "Phone / Cellphone" : "Teléfono / Celular" }
									</label>

									<input
										className="form-control"
										id="userPhone"
										name="phone"
										value={ inputs.phone.data }
										onChange={ handleNumbers } />
								</div>
							</div>

							<div className="row mb-4">
								<div className="col">
									<label htmlFor="body" className="form-label">
										{ cookie.get('i18next') == 'en' ? "Message *" : "Mensaje *" }
									</label>
									<textarea
										className="form-control"
										rows="10"
										name="body"
										value={ inputs.body.data || "" }
										onChange={ handleInputChange }
									></textarea>
									<span className="span-error-message">{ inputs.body.error !== '' ? inputs.body.error : '' }</span>
								</div>
							</div>

							<div style={{ marginTop: '1.3rem', marginBottom: '1.3rem' }}>
								<ReCAPTCHA
								ref={ (r) => setCaptchaRef(r) }
									sitekey="6LctQ20kAAAAADXjhXI7k0EFbbAWmnPeGq_zljRu"
									onChange={onChangeRecaptcha}
								/>
								<span className="span-error-message">{ captchaError !== '' ? captchaError : '' }</span>
							</div>


							<button
								type="submit"
								className="btn btn-form-submit"
								disabled={ !checking ? false : true }>

								{ !checking ?
									cookie.get('i18next') === 'en' ? "Send message" : "Enviar mensaje"
									:
									<>
										<Spinner
											as="span"
											animation="border"
											size="sm"
											role="status"
											aria-hidden="true"
											style={{ marginRight: "10px" }}
										/>
										{ cookie.get('i18next') === 'en' ? "Verifying..." : " Verificando..."  }
								</>
								}
							</button>
						</form>
					</div>
				</div>
			</div>
		</>
	)
}

export default Contact;
