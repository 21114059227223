import { React, useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import ProductLineButtons from '../components/ProductLineButtons';
import ProductGrids from '../components/ProductGrids';
import config from '../config/config.json';
import axios from 'axios';
import Tab from 'react-bootstrap/Tab';
import Loading from '../components/Loading';
import cookie from 'js-cookie';
import { useTranslation } from 'react-i18next';

const CatalogNational = () => {
    document.title = cookie.get('i18next') === 'en' ? "National Catalog - Selecta S.A." : "Catálogo Nacional - Selecta S.A.";

    const { t } = useTranslation();
    const [groups, setGroups] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [groupSelected, setGroupSelected] = useState("YERBA MATE");
    const [groupId, setGroupId] = useState(1);
    const [featuredProducts, setFeaturedProducts] = useState([]);

    useEffect(() => {
        setIsLoading(true)
        let ignore = false;

        async function fetchData() {
            const result = await axios.get(config.SERVER_URL + config.GET_ALL_PRODUCTS_GROUPS);
            if (!ignore) {
                let countProductUnavailable = 0;
                let totalProductsInGroup = 0;
                let filteredGroups = [];

                // Validar si existe un grupo con familias que no corresponde al comercio.
                setGroupId(result.data.data[0].id);

                result.data.data.map(group => {
                    group.families?.map(family => {
                        countProductUnavailable += family.products?.length;
                        totalProductsInGroup += family.products?.filter(p => !p.comercioNac).length;
                    });

                    if(totalProductsInGroup === countProductUnavailable) {
                        filteredGroups.push(group.id);
                    }

                    countProductUnavailable = 0;
                    totalProductsInGroup = 0;
                });

                let groupsFiltered = result.data.data.filter(group => !filteredGroups.includes(group.id));
                setGroups(groupsFiltered);
                // Fin de Validación

                let productsArray = [];
                result.data.data.map(group => {
                    group.families?.map(family => {
                        family.products?.map(product => {
                            productsArray.push(product);
                        })
                    })
                })
                let uniqueValues = [...new Set(productsArray)];
                setFeaturedProducts(uniqueValues);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { ignore = true; }
        }, []);

    function getGroupSelected(group, id) {
        setGroupId(id);
        setGroupSelected(group);
    }
    return (
        <>
            <img data-aos="fade-up" src={ require('../images/comercio_nacional_banner.png') } className="w-100 d-block" />
            <div className="bodyContainer bread-content mt-6">

                <h1 className="greenTitle">
                    { cookie.get('i18next') === "en" ? "NATIONAL CATALOG" : "CATÁLOGO COMERCIO NACIONAL" }
                </h1>

                { !isLoading ?
                    <>
                        <div className="productLines">
                            <div className="productLinesText">
                                <h2 data-aos="fade-right" className='medium-weight grey-text'>{ cookie.get('i18next') === 'en' ? "PRODUCT GROUPS" : "GRUPO DE PRODUCTOS" }</h2>
                                <p data-aos="fade-left" className='grey-text'>{ cookie.get('i18next') === 'en' ? "Meet our food line" : "Conocé nuestra linea de alimentos" }</p>
                            </div>

                            <Tab.Container id="left-tabs-example" defaultActiveKey={ "navItem0" }>
                                { groups && (
                                    <>
                                        <div className="productLinesButtons">
                                            <ProductLineButtons
                                                groupSelected={ getGroupSelected }
                                                groups={ groups }
                                                groupId={ groupId }
                                            />
                                        </div>
                                        <Tab.Content className="product-tab-content">
                                            { groups.map((group, index) => (
                                                <>
                                                    <Tab.Pane className="productGridWrapper" eventKey={ "navItem"+index }>
                                                        { group.families?.slice(0,1).map((familia) => (
                                                            familia.products
                                                            .filter(p => p.comercioNac === true)
                                                            .slice(0,8).map((product) => (
                                                                <ProductGrids
                                                                    product={ product }
                                                                    groupName={ cookie.get('i18next') === "en" ? group.nameEn : group.nameEs }
                                                                    familyName={ cookie.get('i18next') === 'en' ? familia.nameEn : familia.nameEs } />
                                                            ))
                                                        ))}

                                                    </Tab.Pane>
                                                </>
                                            ))}

                                            { groups.length > 0 ?
                                            <div className="text-center mt-5 mb-5 d-flex justify-content-center">
                                                <NavLink
                                                    className="viewMoreProductsButton"
                                                    to={ `/productos/cn/${groupId}/${groupSelected}`}>
                                                    { cookie.get('i18next') === "en" ? "Show more products" : "Ver más productos" }
                                                </NavLink>
                                            </div>
                                            : null }
                                        </Tab.Content>
                                    </>
                                )}
                            </Tab.Container>
                        </div>
                    </>
                    :
                    <Loading /> }
            </div>

            <div data-aos="zoom-in" className="bottom-catalog-wrapper">
                <div className="bottom-catalog-content">
                    { cookie.get('i18next') === 'en' ?
                        <h2 data-aos="fade-up" className="text-white text-center">Learn about our distribution network <br /> in the Country!</h2>
                        :
                        <h2 data-aos="fade-up" className="text-white text-center">¡Conocé nuestra Red de <br />Distribución en el país!</h2>
                    }
                    <div data-aos="fade-up" className="text-center">
                        <NavLink to="/nosotros/red-de-distribucion" className="show-more-bottom-catalog">{ t('show_more') }</NavLink>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CatalogNational;
