import React, { Suspense, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { hydrate, render } from "react-dom";
import './scss/style.scss';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import 'bootstrap/dist/js/bootstrap.js';
import App from './App';
import { HelmetProvider } from 'react-helmet-async';
import Aos from 'aos';
import 'aos/dist/aos.css';
import cookie from 'js-cookie';

import 'flag-icon-css/css/flag-icons.min.css';

Aos.init({
    duration: 1200,
    once: true,
    delay: 200
});

i18next
    .use(HttpApi)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        supportedLngs: ['en', 'es'],
        fallbackLng: 'es',
        debug: false,
        detection: {
            order: ['cookie','path','htmlTag'],
            caches: ['cookie']
        },
        backend: {
            loadPath: '/assets/locales/{{lng}}/translation.json'
        }
});

const loadingMarkup = (
    <div className="py-4 text-center">
        <h3>
            { cookie.get('i18next') === 'en' ?
                "Loading..."
                :
                "Cargando..."
            }
        </h3>
    </div>
)

//const APP = (
//    <HelmetProvider>
//        <Suspense fallback={ loadingMarkup }>
//            <React.StrictMode>
//                <App />
//            </React.StrictMode>
//        </Suspense>
//    </HelmetProvider>
//);

const APP = (
    <HelmetProvider>
        <Suspense fallback={ loadingMarkup }>
            <App />
        </Suspense>
    </HelmetProvider>
);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(APP);

//root.render(
//	<Suspense fallback={ loadingMarkup }>
//		<React.StrictMode>
//			<App />
//		</React.StrictMode>
//	</Suspense>
//);
