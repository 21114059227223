import React, { useState, useEffect } from "react";
import ShareButtons from "../components/ShareButtons";
import cookie from 'js-cookie';
import axios from 'axios';
import config from '../config/config.json';
import Loading from '../components/Loading';

const InnerNews = () => {
	document.title = cookie.get('i18next') === 'en' ? "News - Selecta S.A" : "Noticias - Selecta S.A";
	const newsId = window.location.href.split('/')[5];
	const [loading, setLoading] = useState(false);
	const [newsData, setNewsData] = useState(null);

	const getDate = (date) => {
		let newDate = new Date(date);
		let options = { weekday: "long", day: "numeric", year: "numeric", month: "long" };
		if(cookie.get('i18next') === 'en') {
			return new Intl.DateTimeFormat("en-us", options).format(newDate);
		}
		else {
			return new Intl.DateTimeFormat("es-AR", options).format(newDate);
		}
	}

	useEffect(() => {
		setLoading(true);
		let ignore = false;

		async function getNews() {
			await axios
			.get(config.SERVER_URL + config.GET_SINGLE_NEWS + newsId)
			.then((response) => {
				if(!ignore) {
					setNewsData(response.data.data)
				}
			})
			.catch((error) => {
				console.log(error);
			})
			.then(() => {
				setLoading(false);
			});
		}

		getNews();

		return () => {
			ignore = true;
		}
		}, []);

	return (
		<>
			{ !loading ?
				<div className="bodyContainer mt-6 bread-content">
					{ newsData && (
						<>
							<div className="innerNewsWrapper">
								<img
									className="image-inner-content innerNewsImage"
									src={`${config.ASSET_URL}/${newsData.image}`} />

                                <div className="share-date-wrapper">
                                    <div className="shareButtonsWrapper">
                                        <span>{ cookie.get('i18next') === 'en' ? "Share" : "Compartir" }</span>
                                        <ShareButtons
                                            url={ window.location.href }
                                        />
                                    </div>

                                    <p className="innerNewsDateText text-right">{ getDate(newsData.publishDate) }</p>

                                </div>

								<h1 className="mt-5">
									{ cookie.get('i18next') === 'en' ? newsData.titleEn : newsData.titleEs }
								</h1>

								<div
									className="inner-news-body-container"
									dangerouslySetInnerHTML={{
										__html: cookie.get('i18next') === 'en' ? newsData.bodyEn : newsData.bodyEs
								}} />
							</div>
						</>
					)}
				</div>
				:
				<Loading />
			}
		</>
	)
}

export default InnerNews;
