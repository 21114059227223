import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

function Loading() {
	return (
		<div className="loading-wrapper text-center">
			<Spinner className="loading-spinner" animation="border" variant="success" />
		</div>
	)
}

export default Loading;
