import React from 'react';
import { NavLink } from 'react-router-dom';
import productImage from '../images/placeholder.png';
import config from '../config/config.json';
import { useTranslation } from 'react-i18next';
import cookie from 'js-cookie';

const ProductGrids = ({ product, tradeType, familyName }) => {
    const { t } = useTranslation();
    const getVariety = () => {
        let variety = "";
        if(product.presentations.length > 0) {
            if(product.presentations[0].varieties.length > 0) {
                if(cookie.get('i18next') === 'en') {
                    variety = product.presentations[0].varieties[0].nameEn;
                } else {
                    variety = product.presentations[0].varieties[0].nameEs;
                }
            }
        }

        return variety;
    }

    function toTitleCase(str) {
        return str.replace(
            /\w\S*/g,
            function(txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
    }

    function PascalCase(string) {
        const lowerCase = string.toLowerCase();
        const finalSentence = lowerCase.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
        return finalSentence;
    }

    return (
        <>
            <div data-aos="fade-down" className="product">
                <div className="productPills">
                    { product?.pills.length > 0 && (
                        product.pills.map((pill, index) => (
                            <img 
                                key={ index }
                                className="productPill"
                                src={ cookie.get('i18next') === 'en' ? 
                                    `${config.ASSET_URL}/${ pill.imageEn }` 
                                    : `${config.ASSET_URL}/${ pill.imageEs }`
                                } 
                                alt={ cookie.get('i18next') === 'en' ? pill.nameEn : pill.nameEs } 
                            />
                        ))
                    )}
                </div>
                <NavLink
                    className="showProductButton" to={ tradeType == "cn" ? "/producto/cn/"+product.id : "/producto/ft/"+product.id }>
                    <img src={ product.mainImage !== null ? `${config.ASSET_URL}/${product.mainImage}` : productImage } />
                </NavLink>

                <NavLink
                    className="showProductButton" to={ tradeType == "cn" ? "/producto/cn/"+product.id : "/producto/ft/"+product.id }>
                    <h3>{ toTitleCase(cookie.get('i18next') == 'en' ? product.nameEn : product.nameEs) }</h3>
                </NavLink>

                <p>{ PascalCase(familyName) } - { PascalCase(getVariety()) }</p>

                { false && (
                    <div className="showProductWrapper">
                        <NavLink
                            className="showProductButton" to={ tradeType == "cn" ? "/producto/cn/"+product.id : "/producto/ft/"+product.id }>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                            </svg>
                            { cookie.get('i18next') === 'en' ? "Show product": "Ver producto" }
                        </NavLink>
                    </div>
                )}
            </div>
        </>
    )
}

export default ProductGrids;
