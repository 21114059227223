import { React, useState } from 'react';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import '@splidejs/react-splide/css/core';
import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css";

const SplideImages = (props) => {
    const [state, setState] = useState({
        index: 0,
        open: false
    });

    return (
        <div>
            <Splide
                options={{
                    rewind: true,
                    gap: '1rem',
                    trimSpace: 'move',
                    autoHeight: false,
                    pagination: false,
                    mediaQuery: 'min',
                    breakpoints: {
                        320: {
                            perPage: 1,
                            fixedHeight: 'auto'
                        },
                        740: {
                            perPage: 2,
                            fixedHeight: 'auto'
                        },
                        1100: {
                            perPage: 3,
                            fixedHeight: 'auto'
                        },
                        1600: {
                            perPAge: 3,
                            fixedHeight: 'auto'
                        }
                    }
                }}
                hasTrack={ false }
                aria-label={props.label}>

                <div className="splide__arrows">
                    <button className="splide__arrow splide__arrow--prev arrow-slider-wrapper">
                        <img src={ require('../images/arrow_left.png') } className="arrow-left-slider" />
                    </button>

                    <button className="splide__arrow splide__arrow--next arrow-slider-wrapper">
                        <img src={ require('../images/arrow_right.png') } className="arrow-right-slider" />
                    </button>
                </div>

                <div className="slider-wrapper">
                    <SplideTrack>
                        { props.images && (
                            props.images.map((image, key) => (
                                <SplideSlide key={ key }>
                                    <img 
                                        className="splide-image"
                                        id={ key }
                                        onClick={() => setState({ open: true, index: key })}
                                        src={ image } alt={ `Turismo ${key}` }/>
                                </SplideSlide>
                            ))
                        )}
                    </SplideTrack>
                </div>
            </Splide>

            {state.open && (
                <Lightbox
                    mainSrc={props.images[state.index]}
                    nextSrc={props.images[(state.index + 1) % props.images.length]}
                    prevSrc={props.images[(state.index + props.images.length - 1) % props.images.length]}
                    onCloseRequest={() => setState({ isOpen: false })}
                    onMovePrevRequest={() =>
                        setState(prev => ({ ...prev, index: (state.index + props.images.length - 1) % props.images.length }))
                    }
                    onMoveNextRequest={() =>
                        setState(prev => ({ ...prev, index: (state.index + 1) % props.images.length }))
                    }
                />
            )}
        </div>
    )
}

export default SplideImages;
