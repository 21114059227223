import React, { useState, useEffect } from "react";
import { NavLink } from 'react-router-dom';
import Loading from '../components/Loading';
import axios from 'axios';
import config from '../config/config.json';
import cookie from 'js-cookie';

const Profile = () => {
    document.title = cookie.get('i18next') === 'en' ?
        "My Profile - Selecta S.A." : "Mi Perfil - Selecta S.A.";

    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(null);
    const userId = cookie.get('uid');
    const uStatus = cookie.get('ustatus');

    if(uStatus === "false") {
        window.location.replace("/");
    }

    useEffect(() => {
        setLoading(true);
        let ignore = false;

        async function getUserInformation() {
            await axios
            .get(config.SERVER_URL + config.GET_USER_INFO, {
                headers: { "Authorization": `Bearer ${cookie.get('token')}`}
            })
            .then((response) => {
                if(!ignore) {
                    setUser(response.data.data)
                }
            })
            .catch((error) => {
                console.log(error);
            })
        .then(() => {
                setLoading(false);
            });
        }

        getUserInformation();

        return () => {
            ignore = true;
        }
    }, []);

    return (
        <>
            { !loading ?
                <section className="userSection">
                    <div className="title-profile-wrapper">
                        <h1 className="green-text">
                            { cookie.get('i18next') === 'en' ? "MY PROFILE" : "MI PERFIL" }
                        </h1>

                        <NavLink to={`/editar-perfil`} className="orange-button">
                            { cookie.get('i18next') === "en" ?
                                "Edit information"
                                :
                                "Editar Perfil"
                            }
                        </NavLink>
                    </div>

                    { user && (
                        <>
                            <div className="profile-block ">
                                <p className="green-text">{ cookie.get('i18next') == 'en' ? "First Name" : "Nombre" }</p>
                            <p>{ user.firstName }</p>
                            </div>

                            <div className="profile-block ">
                                <p className="green-text">{ cookie.get('i18next') == 'en' ? "Last Name" : "Apellido" }</p>
                                <p>{ user.lastName }</p>
                            </div>

                            <div className="profile-block ">
                                <p className="green-text">{ cookie.get('i18next') == 'en' ? "E-mail" : "Correo Electrónico" }</p>
                                <p>{ user.email }</p>
                            </div>

                            <div className="profile-block ">
                                <p className="green-text">{ cookie.get('i18next') == 'en' ? "Address" : "Dirección" }</p>
                                <p>{ user.address }</p>
                            </div>

                            <div className="profile-block ">
                                <p className="green-text">{ cookie.get('i18next') == 'en' ? "Phone" : "Teléfono" }</p>
                            <p>{ user.telephone }</p>
                            </div>
                        </>
                    )}

                    <div className="text-mobile-center">
                        <NavLink to={`/cambiar-contrasena`} className="orange-link">
                            { cookie.get('i18next') === 'en' ? "Change my password": "Cambiar Contraseña" }
                        </NavLink>
                    </div>
                </section>
                :
                <Loading />
            }
        </>
    )
}

export default Profile;
