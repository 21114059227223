import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import cookie from 'js-cookie';
import axios from 'axios';
import config from '../config/config.json';
import AlertMessage from "../components/AlertMessage";
import Spinner from 'react-bootstrap/Spinner';
import {useNavigate} from 'react-router-dom';

const Register = () => {
	const navigate = useNavigate();

	useEffect(() => {
		if(cookie.get('ustatus') === "true") {
			navigate('/');
		}
	}, []);

	document.title = cookie.get('i18next') === 'en' ? "Register - Selecta S.A." : "Registro - Selecta S.A.";

	const initialState = {
		firstName: { data: "", error: '' },
		lastName: { data: '', error: '' },
		address: { data: '', error: '' },
		email: { data: '', error: '' },
		password: { data: '', error: '' },
		confirmPassword: { data: '', error: '' },
		phone: { data: '', error: '' }
	}

	const [inputs, setInputs] = useState(initialState);
	const [variant, setVariant] = useState(null);
	const [message, setMessage] = useState(null);
	const [alertShow, setAlertShow] = useState(false);
	const [checking, setChecking] = useState(false);

	const handleInputChange = (event) => {
		const { name, value } = event.target;
		if(value !== '') {
			setInputs(prevState => { return { ...prevState, [name]: { error: '' }}});
		}

		setInputs({ ...inputs, [name] : { data: value }});
	}

	function handleNumbers(event) {
		const reg = /^[0-9\b]+$/;
		if(event.target.value === '' || reg.test(event.target.value)) {
			setInputs({ ...inputs, [event.target.name]: { data: event.target.value }});
		}
	}

	const handleCloseAlert = (show) => {
		setAlertShow(show);
	}

	const validateFields = () => {
		let ready = false;
		let errorFound = 0;
		if(!inputs.firstName.data) {
			setInputs(prevState => { return { ...prevState, firstName: {
				error: cookie.get('i18next') === 'en' ? "Name field is required" : "Debes completar el campo de Nombre"
			}}});
			errorFound++;
			setChecking(false);
		}

		if(!inputs.lastName.data) {
			setInputs(prevState => { return { ...prevState, lastName: {
				error: cookie.get('i18next') === 'en' ? "Last name field is required" : "Debes completar el campo de Apellido"
			}}});
			errorFound++;
			setChecking(false);
		}

		if(!inputs.email.data) {
			setInputs(prevState => { return { ...prevState, email: {
				error: cookie.get('i18next') === 'en' ? "Email field is required" : "Debes completar el campo de Correo Electrónico"
			}}});
			errorFound++;
			setChecking(false);
		}

		if(!inputs.password.data) {
			setInputs(prevState => { return { ...prevState, password: {
				error: cookie.get('i18next') === 'en' ? "Password field is required" : "Debes completar el campo de Contraseña"
			}}});
			errorFound++;
			setChecking(false);
		}

		if(!inputs.password.data || inputs.password.data.length < 8) {
			setInputs(prevState => { return { ...prevState, password: {
				error: cookie.get('i18next') === 'en' ? "The password must contain at least 8 characters and at least one capital letter." : "La contraseña debe tener al menos 8 caracteres y al menos una letra en mayúscula."
			}}});
			errorFound++;
			setChecking(false);
		}

		if(!inputs.confirmPassword.data) {
			setInputs(prevState => { return { ...prevState, confirmPassword: {
				error: cookie.get('i18next') === 'en' ? "Confirm password is required" : "Debes completar el campo de confirmación de contraseña"
			}}});
			errorFound++;
			setChecking(false);
		}

		if(inputs.password.data !== inputs.confirmPassword.data) {
			setInputs(prevState => { return { ...prevState, confirmPassword: {
				error: cookie.get('i18next') === 'en' ? "Confirm password doesn't match with submitted password" : "La confirmación de contraseña es distinta a la contraseña ingresada"
			}}});
			errorFound++;
			setChecking(false);
		}

		if(errorFound <= 0) {
			ready = true;
		}
		return ready;
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		let ignore = false;
		setChecking(true);

		if(validateFields()) {
			axios.post(config.SERVER_URL + config.POST_REGISTER, {
				firstName: inputs.firstName.data,
				lastName: inputs.lastName.data,
				email: inputs.email.data,
				password: inputs.password.data,
				status: "",
				telephone: inputs.phone.data,
				address: inputs.address.data
				})
				.then((response) => {
					if (!ignore) {
						if(response.data.status === "error") {
							setVariant("danger");
							setMessage(response.data.message);
							setAlertShow(true);
							setChecking(false);
						}

						if(response.data.status === "success") {
							handleLoginOnRegister("success");
						}
					}
				})
				.catch((error) => {
					console.log(error);
				})
				.then(() => {
					ignore = true;
			})
		}
	}

	const handleLoginOnRegister = (status) => {
		let ignore = false;

		if(status === "success") {
			axios.post(config.SERVER_URL + config.POST_LOGIN, {
				userMail: inputs.email.data,
				password: inputs.password.data
				})
				.then((response) => {
					if(!ignore) {
						if(response.data.status === "success") {
                            //Call user info API
                            axios.get(config.SERVER_URL + config.GET_USER_INFO, {
                                headers: {
                                    "Authorization": `Bearer ${response.data.data.token}`
                                }
                            })
                            .then((userData) => {
                                if(userData.data.status === "success") {
                                    cookie.set("token", response.data.data.token);
                                    cookie.set("uid", userData.data.data.id);
                                    cookie.set("ustatus", true);
                                    cookie.set("name", userData.data.data.firstName);
                                    cookie.set("lastName", userData.data.data.lastName);
                                    cookie.set("email", userData.data.data.email);
                                    window.location.replace("/");
                                }
                            })
                            .catch((userDataError) => {
                                console.log(userDataError);
                            })

						}
					}
				})
				.catch((error) => {
					console.log("Login on register error: "+error);
				})
				.then(() => {
					ignore = true;
			})
		}
	}

	return (
		<>
			{ alertShow && (
				<AlertMessage
					variant={ variant }
					message={ message }
					showMessage={ alertShow }
					handleShow={ handleCloseAlert } />
			)}

			<section className="userSection">
			<h1 data-aos="fade-down">{ cookie.get('i18next') === 'en' ? "REGISTER" : "REGISTRO" }</h1>
				<p data-aos="fade-down">
					{ cookie.get('i18next') === 'en' ? "Already have an account? " : "¿Ya tenes una cuenta? " }
					<Link to="/iniciar-sesion">{ cookie.get('i18next') === 'en' ? "Log in" : "Iniciar sesión" }</Link></p>

				<form
					className="formUser"
					onSubmit={(e) => { handleSubmit(e) }}>

					<div data-aos="fade-down" className="mb-3"
						>
						<label for="userName" className="form-label">
							{ cookie.get('i18next') === 'en' ? "Name *" : "Nombre *" }
						</label>

						<input
							type="text"
							className="form-control"
							id="userName"
							name="firstName"
							value={ inputs.firstName.data || "" }
							onChange={ handleInputChange } />

							<span className="span-error-message">{ inputs.firstName.error !== '' ? inputs.firstName.error : '' }</span>
					</div>

					<div data-aos="fade-down" className="mb-3">
						<label for="userLastName" className="form-label">
							{ cookie.get('i18next') === 'en' ? "Last Name *" : "Apellido *" }
						</label>

						<input
							type="text"
							className="form-control"
							id="userLastName"
							name="lastName"
							value={ inputs.lastName.data || "" }
							onChange={ handleInputChange } />

							<span className="span-error-message">{ inputs.lastName.error !== '' ? inputs.lastName.error : '' }</span>
					</div>

					<div data-aos="fade-down" className="mb-3">
						<label for="userEmail" className="form-label">Email *</label>

						<input
							type="email"
							className="form-control"
							id="userEmail"
							name="email"
							value={ inputs.email.data || '' }
							onChange={ handleInputChange } />

							<span className="span-error-message">{ inputs.email.error !== '' ? inputs.email.error : '' }</span>
					</div>

					<div data-aos="fade-down" className="mb-3">
						<label for="userAddress" className="form-label">
							{ cookie.get('i18next') === 'en' ? "Address" : "Dirección" }
						</label>

						<input
							type="text"
							className="form-control"
							id="userAddress"
							name="address"
							value={ inputs.address.data || '' }
							onChange={ handleInputChange } />
					</div>

					<div data-aos="fade-down" className="mb-3">
						<label for="userPhone" className="form-label">
							{ cookie.get('i18next') === 'en' ? "Phone / Cellphone" : "Teléfono / Celular" }
						</label>

						<input
							className="form-control"
							id="userPhone"
							name="phone"
							value={ inputs.phone.data }
							onChange={ handleNumbers } />
					</div>

					<div data-aos="fade-down" className="mb-3">
						<label for="loginPassword" className="form-label">
							{ cookie.get('i18next') === 'en' ? "Password *" : "Contraseña *" }
						</label>

						<input
							type="password"
							className="form-control"
							id="loginPassword"
							name="password"
							value={ inputs.password.data || '' }
							onChange={ handleInputChange } />

							<span className="span-error-message">{ inputs.password.error !== '' ? inputs.password.error : '' }</span>
							<i>
							{ cookie.get('i18next') === 'en' ?
								"The password must contain at least 8 characters and at least one capital letter."
								:
								"La contraseña debe contener al menos 8 caracteres y al menos una letra en mayúscula."
							}
							</i>
					</div>

					<div data-aos="fade-down" className="mb-3">
						<label for="loginConfirmPassword" className="form-label">
							{ cookie.get('i18next') === 'en' ? "Confirm Password *" : "Confirmar contraseña *" }
						</label>
						<input
							type="password"
							className="form-control"
							id="loginConfirmPassword"
							name="confirmPassword"
							value={ inputs.confirmPassword.data || '' }
							onChange={ handleInputChange} />

							<span className="span-error-message">{ inputs.confirmPassword.error !== '' ? inputs.confirmPassword.error : '' }</span>
					</div>

					<button
                        data-aos="fade-down"
						type="submit"
						className="btn btnFormLogin"
						disabled={ !checking ? false : true }>
						{ !checking ?
							cookie.get('i18next') === 'en' ? "Register" : "Registrarme"
							:
							<>
								<Spinner
									as="span"
									animation="border"
									size="sm"
									role="status"
									aria-hidden="true"
									style={{ marginRight: "10px" }}
								/>
								{ cookie.get('i18next') === 'en' ? "Verifying..." : " Verificando..."  }
						</>
						}
					</button>
				</form>

			</section>
		</>
	)
}

export default Register;
