import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ProductPresentation from '../components/ProductPresentation';
import ProductGrids from '../components/ProductGrids';
import config from '../config/config.json'
import productImage from '../images/placeholder.png';
import axios from 'axios';
import Loading from '../components/Loading';
import cookie from 'js-cookie';
import { useTranslation } from 'react-i18next';

const InnerProduct = () => {
    let tradeType = window.location.href.split('/')[4];
    let productId = window.location.href.split('/')[5];

	const { t } = useTranslation();
    const [product, setProduct] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingSuggestions, setLoadingSuggestions] = useState(false);
    const [suggestions, setSuggestions] = useState(null);
    const [familyData, setFamilyData] = useState(null);

    const [title, setTitle] = useState("Selecta S.A - Productos");
    const location = useLocation();
    const [selectedProduct, setSelectedProduct] = useState(parseInt(productId));

    useEffect(() => {
        let splitted = location.pathname.split('/');
        setSelectedProduct(splitted[3]);
    }, [window.location.href]);

    useEffect(() => {
        setIsLoading(true)
        let ignore = false;

        function fetchData() {
            axios.get(config.SERVER_URL + config.GET_PRODUCT + selectedProduct)
                .then((response) => {
                    // handle success
                    if(!ignore) {
                        if(cookie.get('i18next') === 'en') {
                            setTitle(response.data.data.detailsEn?.name + " - Selecta S.A");
                        } else {
                            setTitle(response.data.data.detailsEs?.name + " - Selecta S.A");
                        }

                        setProduct(response.data.data);
                    }

                })
                .catch((error) => {
                    console.log(error);
                })
                .then(() => {
                    //Always Executed
                    setIsLoading(false);
                });
        }


        fetchData();

        return () => {
            ignore = true;
        }
    }, [selectedProduct]);

    useEffect(() => {
        if(product) {

            //Assign dynamic title
            document.title = title;

            setLoadingSuggestions(true);
            let ignore = false;

            function fetchSuggestions() {
                axios.get(config.SERVER_URL + config.GET_ALL_PRODUCTS_GROUPS)
                    .then((response) => {
                        if (!ignore) {
                            let productsArray = [];

                            response.data.data.map((group) => {
                                group.families?.filter(family => family.id === product?.familyId).map((family) => {
                                    if(tradeType === 'cn') {
                                        family.products?.filter(p => p.comercioNac === true && p.id !== product.id)
                                            .sort(()=> Math.random() - Math.random()).slice(0,4).map(mapProduct => {
                                                productsArray.push(mapProduct);
                                            })
                                    }
                                    else {
                                        family.products?.filter(p => p.comercioExt === true && p.id != product.id)
                                            .sort(()=> Math.random() - Math.random()).slice(0,4).map(mapProduct => {
                                                productsArray.push(mapProduct);
                                            })
                                    }

                                    setFamilyData(family);
                                })
                            })
                            setSuggestions(productsArray);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .then(() => {
                        setLoadingSuggestions(false);
                    })
            }

            fetchSuggestions();

            return () => {
                ignore = true;
            }
        }

    }, [product]);

    return (
        <>
            { !isLoading ?
                <div className="bodyContainer bread-content">
                    <div className="innerProductWrapper">
                        <div data-aos="fade-right" className="leftInnerProductInformation">

                            <div className='productPills'>
                                { product.pills?.length > 0 && (
                                    product.pills.map((pill, index) => (
                                        <img 
                                            key={ index }
                                            className="productPillInner"
                                            src={ cookie.get('i18next') === 'en' ? 
                                                `${config.ASSET_URL}/${ pill.imageEn }` 
                                                : `${config.ASSET_URL}/${ pill.imageEs }`
                                            } 
                                            alt={ cookie.get('i18next') === 'en' ? pill.nameEn : pill.nameEs } 
                                        />
                                    ))
                                )}
                            </div>

                            {/*<ProductCarousel />*/}
                            <img className="d-block w-100" src={ product.mainImage !== null ? `${config.ASSET_URL}/${product.mainImage}` : productImage } />
                        </div>

                        <div className="rightInnerProductInformation">
                            <h1 data-aos="fade-left">{ cookie.get('i18next') == 'en' ? product.detailsEn?.name : product.detailsEs?.name }</h1>
                            <p data-aos="fade-left">{ product.sku } <span>{ product.barCode }</span></p>

                            { cookie.get('i18next') == 'en' ?
                                <p data-aos="fade-left" className="inner-product-text">
                                    {
                                        product.detailsEn?.longDescription
                                    }
                                </p>
                                :
                                <p data-aos="fade-left" className="inner-product-text">
                                    {
                                        product.detailsEs?.longDescription
                                    }
                                </p>
                            }

                            { cookie.get('i18next') == 'en' ?
                                <p data-aos="fade-left" className="inner-product-text">
                                    { product.detailsEn?.composition }
                                </p>
                                :
                                <p data-aos="fade-left" className="inner-product-text">
                                    { product.detailsEs?.composition }
                                </p>
                            }

                            {/*
                            <NavLink className="mt-3 mb-4 d-inline-block buttonNormal buttonOrange nutritionalInformation">
                                Información nutricional
                            </NavLink>
                            */}

                            { product.presentations?.length > 0 ?
                                <div data-aos="fade-left" className="mt-5 productPresentations">
                                    <h2 data-aos="fade-left" className="title-inner-product">
                                        { cookie.get('i18next') == 'en' ? "PRESENTATIONS": "PRESENTACIONES" }
                                    </h2>
                                    <ProductPresentation presentations={ product.presentations } />
                                </div>
                                : null }
                        </div>
                    </div>

                    {/*
                    <div className="productCertifications">
                        <h2>CERTIFICACIONES</h2>
                        <ProductCertification />
                    </div>
                    */}

                    <div className="suggestedProducts">
                        <h2 data-aos="fade-up">{ cookie.get('i18next') === 'en' ? "SUGGESTIONS": "SUGERENCIAS" }</h2>

                        { !loadingSuggestions ?
                            suggestions && (
                                <div className="productGridWrapper">
                                    { tradeType === "cn" ?
                                        suggestions
                                        .map((product, index) => (
                                            <ProductGrids
                                                key={index+"ps"+product.id}
                                                product={ product }
                                                tradeType={ tradeType }
                                                familyName={ cookie.get('i18next') === 'en' ? familyData.nameEn : familyData.nameEs } />
                                        ))
                                        :
                                        suggestions
                                        .map((product, index) => (
                                            <ProductGrids
                                                key={index+"ps"+product.id}
                                                product={ product }
                                                tradeType={ tradeType }
                                                familyName={ cookie.get('i18next') === 'en' ? familyData.nameEn : familyData.nameEs } />
                                        ))
                                    }
                                </div>
                            )
                            : <Loading />
                        }
                    </div>

                </div>
                :
                <Loading />
            }
        </>
    )
}

export default InnerProduct;
