import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import leftLeaf from '../images/leaf_group_left.png';
import rightLeaf from '../images/leaf_group_right.png';
import cookie from 'js-cookie';
import { useMediaQuery } from 'react-responsive';

const SelectaDistributions = () => {
	document.title = cookie.get('i18next') === 'en' ? "Distribution network - Selecta S.A" : "Distribuidores - Selecta S.A";
    const isMobileToTablet = useMediaQuery({ minWidth: '20em', maxWidth: '46.24em' });
	const { t } = useTranslation();
	return (
		<>
            { isMobileToTablet ?
                <img src={
                    cookie.get('i18next') === 'en' ?
                        require('../images/distribucion_title_mobile_en.webp') :
                        require('../images/distribucion_title_mobile.webp')
                    }
                    className="imageSection"
                    alt="Distribuidores - Selecta S.A" />
                :
                <img src={
                    cookie.get('i18next') === 'en' ?
                        require('../images/distribucion_title_en.webp') :
                        require('../images/distribucion_title.webp')
                    }
                    className="imageSection"
                    alt="Distribuidores - Selecta S.A" />
            }

			<div className="bodyContainer">
                <div className="index-content-wrapper">
                    <img data-aos="fade-right" className="big-leaf-left" src={ leftLeaf } />
                    <img data-aos="fade-left" className="big-leaf-right" src={rightLeaf } />
                    <div className="top-distributions-wrapper bread-content">
                        <h2 data-aos="fade-up" className="greenTitle mb-4 text-center">{ t('nd_title') }</h2>
                        <p data-aos="fade-up text-center">
                            { t('nd_p1') }
                        </p>

                        <div className="distribuitors-wrapper">
                            <div data-aos="fade-down" className="distribuitor-block">
                                <h3>San Lorenzo</h3>
                                <div className="distribuitor-information-block">
                                    <FontAwesomeIcon icon={ faLocationDot } />
                                    <a href="https://goo.gl/maps/Dx47xLFgw6h3Vbo97" target="_blank">
                                        <p>Florida casi Sargento Silva - Barrio Florida – San Lorenzo - Central</p>
                                    </a>
                                </div>

                                <div className="distribuitor-information-block">
                                    <FontAwesomeIcon icon={ faPhone } />
                                    <p>021 584 011</p>
                                </div>
                            </div>

                            <div data-aos="fade-down" className="distribuitor-block">
                            <h3>Yby Yau</h3>
                                <div className="distribuitor-information-block">
                                    <FontAwesomeIcon icon={ faLocationDot } />
                                    <a href="https://goo.gl/maps/HWjNsrW7EXktQHzx7" target="_blank">
                                        <p>Ruta 5 km 111.5 – Yby Yau – Concepción</p>
                                    </a>
                                </div>

                                <div className="distribuitor-information-block">
                                    <FontAwesomeIcon icon={ faPhone } />
                                    <p>039 210 459</p>
                                </div>
                            </div>

                            <div data-aos="fade-down" className="distribuitor-block">
                                <h3>Coronel Oviedo</h3>
                                <div className="distribuitor-information-block">
                                    <FontAwesomeIcon icon={ faLocationDot } />
                                    <a href="https://goo.gl/maps/JQsUzZjT9aZRK38L9" target="_blank">
                                        <p>Ruta 8 km 134 - Barrio José Maria Alfonso Godoy – Cnel. Oviedo - Caaguazú</p>
                                    </a>
                                </div>

                                <div className="distribuitor-information-block">
                                    <FontAwesomeIcon icon={ faPhone } />
                                    <p>0985 951 277</p>
                                </div>
                            </div>

                            <div data-aos="fade-down" className="distribuitor-block">
                            <h3>Misiones</h3>
                                <div className="distribuitor-information-block">
                                    <FontAwesomeIcon icon={ faLocationDot } />
                                    <a href="https://maps.app.goo.gl/cTggg5Be824UUqj77" target="_blank">
                                        <p>14 de mayo y Alberdi -  Barrio Cristo Rey - Santa Rosa - Misiones</p>
                                    </a>
                                </div>

                                <div className="distribuitor-information-block">
                                    <FontAwesomeIcon icon={ faPhone } />
                                    <p>0985 133 999</p>
                                </div>
                            </div>

                            <div data-aos="fade-down" className="distribuitor-block">
                            <h3>Chaco</h3>
                                <div className="distribuitor-information-block">
                                    <FontAwesomeIcon icon={ faLocationDot } />
                                    <a href="https://goo.gl/maps/L37xULVXab9UtkX39" target="_blank">
                                        <p>Calle Paratodo casi ruta 9 – Barrio Primavera – Filadelfia – Boquerón</p>
                                    </a>
                                </div>
                            </div>

                            <div data-aos="fade-down" className="distribuitor-block">
                                <h3>Distribuidora La Saeta</h3>
                                <div className="distribuitor-information-block">
                                    <FontAwesomeIcon icon={ faLocationDot } />
                                    <a href="https://goo.gl/maps/DChcUtQxxtFn9SDf7" target="_blank">
                                        <p>Casa Matriz Avda. Marcial Samaniego km … Barrio Paraíso – Bella Vista – Itapúa </p>
                                    </a>
                                </div>

                                <div className="distribuitor-information-block">
                                    <FontAwesomeIcon icon={ faPhone } />
                                    <p>0767 240 606</p>
                                </div>
                            </div>

                            <div data-aos="fade-down" className="distribuitor-block">
                                <h3>Distribuidora La Saeta</h3>
                                <div className="distribuitor-information-block">
                                    <FontAwesomeIcon icon={ faLocationDot } />
                                    <a href="https://goo.gl/maps/hUPJiwA8XEEA88dF8" target="_blank">
                                        <p>Sucursal Ciudad del Este Avda. Juan E Oleari, km 8 Barrio San Isidro – Alto Paraná</p>
                                    </a>
                                </div>

                                <div className="distribuitor-information-block">
                                    <FontAwesomeIcon icon={ faPhone } />
                                    <p>0985 239 699</p>
                                </div>
                            </div>

                            <div data-aos="fade-down" className="distribuitor-block">
                                <h3>Distribuidora Comercial 40</h3>
                                <div className="distribuitor-information-block">
                                    <FontAwesomeIcon icon={ faLocationDot } />
                                    <a href="https://goo.gl/maps/i9GwjM9B4uTGQ1427" target="_blank">
                                        <p>Calle Padre Queiroz Candia c/ Aquidaban Concepción – Concepción</p>
                                    </a>
                                </div>

                                <div className="distribuitor-information-block">
                                    <FontAwesomeIcon icon={ faPhone } />
                                    <p>0331 240 337</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
			</div>
		</>
	)
}

export default SelectaDistributions;

