import React, { useState, useEffect } from 'react';
import config from '../config/config.json';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import i18next from 'i18next';
import cookie from 'js-cookie';
import { NavLink, Link } from 'react-router-dom';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Accordion from 'react-bootstrap/Accordion';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';

const languages = [
  {
    code: 'es',
    name: 'Español',
    country_code: 'py',
  },
  {
    code: 'en',
    name: 'English',
    country_code: 'gb',
  }
];

const MenuMobile = () => {
	const { t } = useTranslation();
	const [state, setState] = useState({
		groups: []
	});

	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const CheckFlag = () => {
		if(cookie.get('i18next') == 'en') {
			return <span className="flag-icon flag-icon-gb"></span>;
		}
		else if(cookie.get('i18next') == 'es') {
			return <span className="flag-icon flag-icon-py"></span>;
		}
		else {
			return <GlobeIcon />;
		}
	}
	// change the language

	const handleLogout = () => {
		if(cookie.get('ustatus')) {
			cookie.set('ustatus', false);
			cookie.set("token", "");
			cookie.set("uid", "");
			cookie.set("name", "");
			cookie.set("lastName", "");
			cookie.set("email", "");
		}
		window.location.replace('/');
	}

	useEffect(() => {
		let ignore = false;

		async function fetchData() {
			const result = await axios(config.SERVER_URL + config.GET_ALL_GROUPS);
			if (!ignore) setState(prev => ({ ...prev, groups: result.data.data}));
		}

		fetchData();
		return () => { ignore = true; }
	}, []);

	function PascalCase(string) {
		const lowerCase = string.toLowerCase();
		const finalSentence = lowerCase.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
		return finalSentence
	}

	const GlobeIcon = ({ width = 24, height = 24 }) => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			fill="currentColor"
			className="bi bi-globe"
			viewBox="0 0 16 16"
		>
			<path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z" />
		</svg>
	)

	return (
		<>
			<button variant="primary" className="button-menu-mobile" onClick={ handleShow }>
				<img src={ require('../images/hamb_menu_white.png') } />
			</button>

			<Offcanvas className="offcanvas-mobile" show={show} onHide={handleClose}>
				<Offcanvas.Header className="pt-4 pb-4" closeButton>
					<Offcanvas.Title>
                        { false && (
                            <img src={ require('../images/logo.png') } className="mobile-menu-logo" />
                        )}
					</Offcanvas.Title>
				</Offcanvas.Header>

				{ cookie.get('ustatus') == 'true' ?
					<h2 className="welcome-mobile-menu">
						{ cookie.get('i18next') == 'en' ?
							`Welcome, ${cookie.get('name')}`
							:
							`Bienvenido/a, ${cookie.get('name')}`
						}
					</h2>
					: null
				}

				<Offcanvas.Body>
					<ul className="menu-list-mobile">
						<li>
							<Accordion>
								<Accordion.Item className="accordion-item-menu-mobile" eventKey="0">
									<Accordion.Header className="accordion-menu-mobile">{ t('menu_about_us') }</Accordion.Header>
									<Accordion.Body>
										<ul>
											<li>
												<Link to='/nosotros/grupo-selecta' className="menu-list-link" onClick={ handleClose }>
													{ t('menu_selecta_group') }
												</Link>
											</li>
											<li>
												<Link to='/nosotros/empresa' className="menu-list-link" onClick={ handleClose }>
													{ t('menu_business') }
												</Link>
											</li>
											<li>
												<Link to='/nosotros/red-de-distribucion' className="menu-list-link" onClick={ handleClose }>
													{ t('menu_network_distribution') }
												</Link>
											</li>
											<li>
												<Link to='/social' className="menu-list-link" onClick={ handleClose }>
													{ t('menu_social') }
												</Link>
											</li>
											<li>
												<Link to='/noticias' className="menu-list-link" onClick={ handleClose }>
													{ t('menu_news') }
												</Link>
											</li>
										</ul>
									</Accordion.Body>
								</Accordion.Item>
							</Accordion>
						</li>
						<li>
							<Link to="/yerba-mate" className="menu-list-link" onClick={ handleClose }>
								Yerba Mate
							</Link>
						</li>
						<li>
							<Link to="/turismo" className="menu-list-link" onClick={ handleClose }>
								{ t('menu_tourism') }
							</Link>
						</li>
						<li>
							<Accordion>
								<Accordion.Item className="accordion-item-menu-mobile" eventKey="0">
									<Accordion.Header className="accordion-menu-mobile">{ t('menu_catalog') }</Accordion.Header>
									<Accordion.Body>
										<ul>
											<li>
												<Accordion>
													<Accordion.Item className="accordion-item-menu-mobile" eventKey="0">
														<Accordion.Header className="accordion-menu-mobile">{ t('menu_foreign_trade') }</Accordion.Header>
														<Accordion.Body>
															<ul>
																<li>
																	<Link to="/catalogos/comercio-exterior" className="menu-list-link" onClick={ handleClose }>
																		{	cookie.get('i18next') == "en" ? "Show catalog" : "Ver catálogo" }
																	</Link>
																	{ state.groups.length > 0 ?
																		state.groups.map((group,i) => (
																		group.comercioExt ?
																				<Accordion>
																					<Accordion.Item className="accordion-item-menu-mobile" eventKey="0">
																						<Accordion.Header className="accordion-menu-mobile">{ cookie.get('i18next') == 'en' ? group.nameEn : group.nameEs }</Accordion.Header>
																						<Accordion.Body>
																							<ul>
																								{ group.familias.length > 0 ?
																									group.familias.map((familia,j) => (
																										familia.comercioExt ?
																											<li>
																												<NavLink id={j} to={`/productos/ft/${group.id}/${cookie.get('i18next') == 'en' ? group.nameEn : group.nameEs}/${familia.id}`} className="menu-list-link" onClick={ handleClose }>
																													{ PascalCase(cookie.get('i18next') == 'en' ? familia.nameEn : familia.nameEs) }
																												</NavLink>
																											</li>
																										: null
																									))
																								: null }
																							</ul>
																						</Accordion.Body>
																					</Accordion.Item>
																				</Accordion>
																		: null
																		))
																	:
																	null
																	}
																</li>
															</ul>
														</Accordion.Body>
													</Accordion.Item>
												</Accordion>
											</li>

											<li>
												<Accordion>
													<Accordion.Item className="accordion-item-menu-mobile" eventKey="0">
														<Accordion.Header className="accordion-menu-mobile">{ t('menu_national') }</Accordion.Header>
														<Accordion.Body>
															<ul>
																<li>
																	<Link to="/catalogos/nacional" className="menu-list-link" onClick={ handleClose }>
																		{	cookie.get('i18next') == "en" ? "Show catalog" : "Ver catálogo" }
																	</Link>
																	{ state.groups.length > 0 ?
																		state.groups.map((group,i) => (
																		group.comercioNac ?
																				<Accordion>
																					<Accordion.Item className="accordion-item-menu-mobile" eventKey="0">
																						<Accordion.Header className="accordion-menu-mobile">{ cookie.get('i18next') == 'en' ? group.nameEn : group.nameEs }</Accordion.Header>
																						<Accordion.Body>
																							<ul>
																								{ group.familias.length > 0 ?
																									group.familias.map((familia,j) => (
																										familia.comercioNac ?
																											<li>
																												<NavLink id={j} to={`/productos/cn/${group.id}/${cookie.get('i18next') == 'en' ? group.nameEn : group.nameEs}/${familia.id}`} className="menu-list-link" onClick={ handleClose }>
																													{ PascalCase(cookie.get('i18next') == 'en' ? familia.nameEn : familia.nameEs) }
																												</NavLink>
																											</li>
																										: null
																									))
																								: null }
																							</ul>
																						</Accordion.Body>
																					</Accordion.Item>
																				</Accordion>
																		: null
																		))
																	:
																	null
																	}
																</li>
															</ul>
														</Accordion.Body>
													</Accordion.Item>
												</Accordion>
											</li>
										</ul>
									</Accordion.Body>
								</Accordion.Item>
							</Accordion>
						</li>

						<li>
							<Accordion>
								<Accordion.Item className="accordion-item-menu-mobile" eventKey="0">
									<Accordion.Header className="accordion-menu-mobile">{ cookie.get('i18next') == 'en' ? "Foreign Trade" : "Comercio Exterior" }</Accordion.Header>
									<Accordion.Body>
										<ul>

											<li>
												<NavLink
													className="menu-list-link"
													onClick={ handleClose }
													to="/comercio-exterior">{ t('menu_foreign_trade') }
												</NavLink>
											</li>
											<li>
												<NavLink
													className="menu-list-link"
													onClick={ handleClose }
													to="/distribuidores">{ t('menu_distributors') }
												</NavLink>
											</li>
                                            { false && (
                                                <li>
                                                    <NavLink
                                                        className="menu-list-link"
                                                        onClick={ handleClose }
                                                        to="/noticias/comercio-exterior">{ t('menu_foreign_trade_news') }
                                                    </NavLink>
                                                </li>
                                            )}

											{/*
											<li>
												<NavLink to="#">{ t('menu_foreign_trade_contact') }
												</NavLink>
											</li>
											*/}

										</ul>
									</Accordion.Body>
								</Accordion.Item>
							</Accordion>
						</li>
						<li>
							<Link to="/recetas" className="menu-list-link" onClick={ handleClose }>
								{ t('menu_recipes') }
							</Link>
						</li>
						<li>
							<Link to="/contacto" className="menu-list-link" onClick={ handleClose }>
								{ t('menu_contact_us') }
							</Link>
						</li>
					</ul>


					<ul className="optionsList">
						{ cookie.get('ustatus') == 'true' ?
							<li>
								<Accordion>
									<Accordion.Item className="accordion-item-menu-mobile" eventKey="0">
										<Accordion.Header className="accordion-menu-mobile">
											<span style={{ marginRight: '10px' }}>
												{ cookie.get('i18next') == 'en' ?
													`My account`
													:
													`Mi Cuenta`
												}
											</span>
										</Accordion.Header>

										<Accordion.Body>
											<ul className="ul-list-user-mobile">
												<li>
												<NavLink onClick={ handleClose } to="/perfil">
													<PersonIcon />
													{ cookie.get('i18next') == 'en' ? "Show profile" : "Ver perfil" }
												</NavLink>
												</li>

												<li>
													<button onClick={ handleLogout }>
														<LogoutIcon />
														{ cookie.get('i18next') == 'en' ? "Logout" : "Cerrar sesión" }
													</button>
												</li>
											</ul>
										</Accordion.Body>
									</Accordion.Item>
								</Accordion>
							</li>
							:
							<>
								<li>
									<NavLink to="/iniciar-sesion" onClick={ handleClose } className="button buttonOptionMenu buttonOrange">
										{ t('menu_log_in') }
									</NavLink>
								</li>
								<li>
									<NavLink to="/registrate" onClick={ handleClose } className="button buttonOptionMenu buttonOrangeReverse">{ t('menu_register') }</NavLink>
								</li>
						</>
						}

						<li>
							<Accordion>
								<Accordion.Item className="accordion-item-menu-mobile" eventKey="0">
									<Accordion.Header className="accordion-menu-mobile">
										<span style={{ marginRight: '10px' }}>
											{ cookie.get('i18next') == 'en' ?
											"Select a language"
											:
											"Seleccionar idioma"
											}
										</span>
										<CheckFlag />
									</Accordion.Header>
									<Accordion.Body>
										<ul>
											{ languages.map(({ code, name, country_code }) => (
												<li key={ country_code }>
													<button class="dropdown-item" onClick={ () => { i18next.changeLanguage(code) }}>
														<span className={`flag-icon flag-icon-${country_code} mx-2`}></span>
														{ name }
													</button>
												</li>
											))}
										</ul>
									</Accordion.Body>
								</Accordion.Item>
							</Accordion>
						</li>

					</ul>
				</Offcanvas.Body>
			</Offcanvas>
		</>
	)
}

export default MenuMobile;
