import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/logo.png';
import { useMediaQuery } from 'react-responsive';
import MenuMobile from './MenuMobile';
import MenuDesktop from './MenuDesktop';

const Header = () => {
    sessionStorage.clear();
    const isMobileToTablet = useMediaQuery({ minWidth: '20em', maxWidth: '46.24em' });
    const isTabletToDesktop = useMediaQuery({ minWidth: '46.25em', maxWidth: '68.74em' });
    return (
        <header className="mainHeader">
            <div className="headerContainer menuContainer">
                <Link to="/" className="logoLink">
                    <img src={ logo } alt="Selecta" className="logo-header" />
                </Link>

                { isMobileToTablet || isTabletToDesktop ? <MenuMobile /> : <MenuDesktop /> }
            </div>
        </header>
    );
};

export default Header;
