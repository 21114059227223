import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import ReactPlayer from 'react-player/lazy'
import topImage from '../images/corte_bottom.png';
import leftLeaf from '../images/left_leaf.png';
import rightLeaf from '../images/right_leaf.png';
import lonelyLeaf from '../images/leaf.png';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import ProductGrids from '../components/ProductGrids';
import { useMediaQuery } from 'react-responsive';
import TopVideo from '../videos/selecta.mp4';
import VideoComex from '../videos/grupo_selecta_es.mp4';
import config from '../config/config.json';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import cookie from 'js-cookie';
import Loading from '../components/Loading';
import YerbaProperties from '../components/YerbaProperties';

const Home = () => {
    document.title = "Selecta S.A";
    const { t } = useTranslation();
    const isMobileToDesktop = useMediaQuery({ minWidth: '20em', maxWidth: '68.74em' });

    const [isLoading, setIsLoading] = useState(false);
    const [groups, setGroups] = useState(null);
    const [recipes, setRecipes] = useState(null);

    function PascalCase(string) {
        const lowerCase = string.toLowerCase();
        const finalSentence = lowerCase.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
        return finalSentence;
    }

    useEffect(() => {
        setIsLoading(true);
        let ignore = false;

        async function fetchData() {
            const result = await axios.get(config.SERVER_URL + config.GET_ALL_PRODUCTS_GROUPS_INDEX);
            if (!ignore) {
                setGroups(result.data.data);
            }
        }

        async function getRecipes() {
            await axios
            .get(config.SERVER_URL + config.GET_ALL_RECIPES)
            .then((response) => {
                if(!ignore) {
                    setRecipes(response.data.data);
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .then(() => {
            });
        }

        fetchData();
        getRecipes();

        setIsLoading(false);

        return () => {
            ignore = true;
        }
    }, []);

    return (
        <>
            <div className="top-wrapper">
                <div data-aos="fade-down" className="top-index-player">
                    <ReactPlayer
                        className='react-player'
                        playing={true}
                        url={[TopVideo,'mp4']}
                        volume={0}
                        muted={true}
                        width="100%"
                        height="100%"
                        loop
                        config= {{
                            file: {
                                muted: true
                            }
                        }}
                    />
                </div>
                <img src={ topImage } />
            </div>

            <div className="bodyContainer mb-0">
                <div className="index-content-wrapper bread-content">
                    { false && (
                        <>
                            <img data-aos="fade-right" className="big-leaf-left" src={ leftLeaf } />
                            <img data-aos="fade-left" className="big-leaf-right" src={rightLeaf } />
                        </>
                    )}

                    <h2 data-aos="fade-right" className="greenTitle mb-4">{ t('home_product_title') }</h2>
                    { !isLoading ?
                        <Tab.Container transition={true} id="left-tabs-example" defaultActiveKey={"navItem0"}>
                            <Row>
                                <Col data-aos="fade-right" className="col-buttons-index-products" sm={3}>
                                    { groups && (
                                        <Nav className={ isMobileToDesktop ? "nav-index-products flex-row" : "nav-index-products flex-column" }>
                                            { groups.map((group, index) => (
                                                <Nav.Item key={index}>
                                                    <Nav.Link className="nav-top-products" eventKey={`navItem${index}`}>
                                                        { PascalCase(cookie.get('i18next') === 'en' ? group.nameEn : group.nameEs) }
                                                    </Nav.Link>
                                                </Nav.Item>
                                            ))}
                                        </Nav>
                                    )}
                                </Col>

                                <Col data-aos="fade-left" className="col-buttons-index-products" sm={9}>
                                    { groups && (
                                        <Tab.Content>
                                            { groups.map((g, i) => (
                                                <Tab.Pane eventKey={`navItem${i}`}>
                                                    <div className="indexGridWrapper productGridWrapper">
                                                        { g.families?.map(f => (
                                                            f.products
                                                            .map(product => (
                                                                <ProductGrids
                                                                    product={ product }
                                                                    groupName={ cookie.get('i18next') === "en" ? g.nameEn : g.nameEs }
                                                                    familyName={ cookie.get('i18next') === 'en' ? f.nameEn : f.nameEs } 
                                                                />
                                                            ))
                                                        ))}
                                                    </div>
                                                </Tab.Pane>
                                            ))}
                                        </Tab.Content>
                                    )}
                                </Col>
                            </Row>
                        </Tab.Container>
                        :
                        <Loading />
                    }
                </div>

                <div className="index-content-wrapper mb-0">
                    <img data-aos="fade-right" className="lonely-left-leaf" src={ lonelyLeaf } />
                    <img data-aos="fade-left" className="lonely-right-leaf" src={ lonelyLeaf } />

                    <YerbaProperties />

                    <div className="index-grid-content bread-content mt-6 two-block-wrapper two-block-wrapper-index-foreign-wrapper">
                        <div data-aos="fade-right" className="grid-video-wrapper">

                            <div data-aos="fade-left" className="grid-video">
                                <ReactPlayer
                                    className='react-player'
                                    url={[VideoComex,'mp4']}
                                    muted={false}
                                    width="100%"
                                    height="100%"
                                    config= {{
                                        file: {
                                            attributes: {
                                                controls: true,
                                                autopictureinpicture: true,
                                                poster: require('../videos/selecta_group_poster.png'),
                                            }
                                        }
                                    }}
                                />
                            </div>
                        </div>

                        <div data-aos="fade-left" className="index-content grid-content">
                            <h2 data-aos="fade-right" className="greenTitle mb-4">{ t('foreign_trade_title') }</h2>
                            <p className="p-text-normal-block" data-aos="fade-right">
                                { t('foreign_trade_p1') }
                            </p>

                            <p className="p-text-normal-block" data-aos="fade-right">
                                { t('foreign_trade_p2') }
                            </p>
                        </div>
                    </div>

                    <div className="mb-0 bg-receta-index">
                        <div className="grid-content-wrapper no-margin-bottom-mobile no-margin-bottom-wide">
                            <div data-aos="fade-right" className="index-recipe-grid">
                                <img data-aos="fade-down" src={
                                    cookie.get('i18next') == 'en' ?
                                        require('../images/english/selecta_recipes.png') :
                                        require('../images/selecta_recetas.png')
                                } />

                                <p data-aos="fade-down">{ t('home_recipe_description') }</p>
                                <NavLink data-aos="fade-up" className="show-more-recipe" to="/recetas">
                                    { cookie.get('i18next') == 'en' ? 'Show more >>' : 'Ver más >>' }
                                </NavLink>
                            </div>

                            { !isLoading ?
                                recipes && (
                                    <div data-aos="fade-left" className="recipe-grid-index-wrapper home-recipes">
                                        { recipes.slice(0,2).map((recipe, key) => (
                                            <div className="recipe-index border border-0 mb-0">
                                                <NavLink className="link-img-index-recipe d-block mb-2" to={`/recetas/${recipe.id}`}>
                                                    <img
                                                        className="w-100 d-block image-index-grid"
                                                        src={ `${config.ASSET_URL}/${recipe.image}` }
                                                    />
                                                </NavLink>

                                                <NavLink to={`/recetas/${recipe.id}`}>
                                                    <h3 className="px-3">{ cookie.get('i18next') == 'en' ? recipe.titleEn : recipe.titleEs }</h3>
                                                </NavLink>

                                                <p className="px-3"><b>Producto:</b> { cookie.get('i18next') == "en" ? recipe.productNameEn : recipe.productNameEs }</p>
                                            </div>
                                        ))}
                                    </div>
                                )
                                :
                                <Loading />
                            }
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Home;
