import React from 'react';
import { Page, Text, View, Image } from '@react-pdf/renderer';
import config from '../config/config.json';
import { useTranslation } from 'react-i18next';
import cookie from 'js-cookie';

const ProductCatalogPage = ({ product, groupName, familyName, styles }) => {
    const { t } = useTranslation();

    return (
        <Page size="A4" style={styles.page}>
            <View style={styles.productTitle}>
                <Text>
                    {groupName}
                </Text>
                <Text>
                    { `${familyName} - ${cookie.get('i18next') === 'en' ? product.nameEn : product.nameEs}` }
                </Text>
            </View>

            <View style={ styles.presentationSection }>
                <Image style={ styles.productImage } 
                    src={ `${config.ASSET_URL}/${ product.mainImage }`} 
                />

                <View style={ styles.presentationBlock }>
                    <Text style={ styles.presentationTitle }>
                        { cookie.get('i18next') === 'en' ?
                            "PRESENTATION"
                            : "PRESENTACIÓN" }
                    </Text>

                    <Text style={ styles.presentationText }>
                        { cookie.get('i18next') === 'en' 
                            ? product.presentations[0].nameEn 
                            : product.presentations[0].nameEs }
                    </Text>

                    <Text style={ styles.presentationTitle }>
                        { cookie.get('i18next') === 'en' ?
                            "VARIETIES"
                            : "VARIEDADES" }
                    </Text>

                    <Text style={ styles.presentationText }>
                        { cookie.get('i18next') === 'en' 
                            ? 
                            product.presentations[0].varieties[0].nameEn 
                            : product.presentations[0].varieties[0].nameEs }
                    </Text>
                </View>
            </View>

            <View style={ styles.productDescription }>

                <Text style={ styles.productDescriptionText }>
                    { cookie.get('i18next') === 'en' ? 
                        product.detailsEn.longDescription 
                        : product.detailsEs.longDescription
                    }
                </Text>

                <Text style={ styles.productDescriptionText }>
                    { cookie.get('i18next') === 'en' ? 
                        product.detailsEn.compsition 
                        : product.detailsEs.composition
                    }
                </Text>
            </View>
        </Page>
    )
}; 

export default ProductCatalogPage;
