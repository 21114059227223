import { NavLink, Link } from 'react-router-dom';
import config from '../config/config.json';
import cookie from 'js-cookie';

export default function NewsItem({ key, item, type }) {
    return (
        <div key={key} data-aos="fade-down" className="news-grid-container">
            <NavLink className="link-img-news d-block mb-2" to={`/noticia/${type}/${item.id}`}>
                <img
                    className="w-100 d-block image-grid-news"
                    src={ `${config.ASSET_URL}/${item.image}` }
                />
            </NavLink>

            <NavLink to={`/noticia/${type}/${item.id}`}>
                <h3>{ cookie.get('i18next') === 'en' ? item.titleEn : item.titleEs }</h3>
            </NavLink>

            <p>{ cookie.get('i18next') === "en" ? item.descriptionEn : item.descriptionEs }</p>

            <div className="show-more-recipe-wrapper">
                <Link
                    to={`/noticia/${type}/${item.id}`}
                    className="newsBlockButton">Ver Noticia
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                    </svg>
                </Link>
            </div>
        </div>
    )
}
