import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import WhatsappIcon from '../components/WhatsappIcon';

function Layout(props) {
	return (
		<div className="globalContainer">
			<Header />
			<main>
				{ props.children }
			</main>
			<WhatsappIcon />
			<Footer />
		</div>
	)
}

export default Layout;
