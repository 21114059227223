import React from 'react';
import { Document } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import ProductCatalogPage from './ProductCatalogPage';
import imageLogo from '../images/footer_logo.png';
import { Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import cookie from 'js-cookie';

const styles = StyleSheet.create({
    portraitPage: {
        backgroundColor: '#FFFFFF',
    },

    portraitPageTitle: {
        fontSize: 45,
        padding: 20,
        textAlign: 'center',
        fontWeight: 700,
        color: '#014923',
        marginTop: 20
    },

    page: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
    },

    presentationSection: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 50,
        padding: 15
    },

    productImage: {
        flexShrink: 1,
        flexGrow: 0,
        width: 400,
        display: 'block'
    },

    presentationBlock: {
        width: 280,
        flexShrink: 1,
        flexGrow: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 10
    },

    productTitle: {
        fontSize: 15,
        padding: 10,
        marginTop: 30,
        color: '#fff',
        backgroundColor: '#00671f',
        width: 300,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 10,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 10,
        borderBottomWidth: 7,
        borderBottomColor: '#ee6003',
        borderBottomStyle: 'solid',
    },

    presentationTitle: {
        fontSize: 14,
        color: '#ffffff',
        backgroundColor: '#00671f',
        textAlign: 'center',
        fontWeight: 'bold',
        paddingTop: 7,
        paddingBottom: 7,
        marginBottom: 5,
        width: '100%',
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        borderBottomWidth: 4,
        borderBottomColor: '#ee6003',
        borderBottomStyle: 'solid',
    },

    logo: {
        width: 280,
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '50%',
        marginBottom: 0
    },

    presentationText: {
        fontSize: 14,
        marginBottom: 25
    },

    productDescription: {
        padding: 20
    },

    productDescriptionText: {
        marginBottom: 20,
        fontSize: 14
    }

});

// Create Document Component
const ProductCatalog = () => {
    const { t } = useTranslation();
    let fetchedData = JSON.parse(sessionStorage.getItem('groups'));
    let groupName = sessionStorage.getItem('groupName');
    let tradeType = sessionStorage.getItem('tradeType');

    let today = new Date();
    let currentYear = today.getFullYear();

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <Image src={ imageLogo } style={ styles.logo } />
                <View style={ styles.portraitPage }>
                    <Text style={ styles.portraitPageTitle }>
                        CATÁLOGO SELECTA { currentYear }
                    </Text>
                </View>
            </Page>

            { tradeType === "cn" ?
                "selectedFamily" in sessionStorage ?
                    fetchedData.families?.filter(f => f.id === parseInt(sessionStorage.getItem('selectedFamily')))
                    .map((family) => (
                        family.products?.filter(p => p.comercioNac === true)
                        .map((product, index) => (
                            <ProductCatalogPage 
                                key={ index } 
                                familyName={ cookie.get('i18next') === 'en' ? family.nameEn : family.nameEs }
                                product={ product } 
                                groupName={ groupName } 
                                styles={ styles } />
                        ))
                    ))
                    :
                    fetchedData.families?.map((family) => (
                        family.products?.filter(p => p.comercioNac === true)
                        .map((product, index) => (
                            <ProductCatalogPage 
                                key={ index } 
                                familyName={ cookie.get('i18next') === 'en' ? family.nameEn : family.nameEs }
                                product={ product } 
                                groupName={ groupName } 
                                styles={ styles } />
                        ))
                    ))
                :
                "selectedFamily" in sessionStorage ?
                    fetchedData.families?.filter(f => f.id === parseInt(sessionStorage.getItem('selectedFamily')))
                    .map((family) => (
                        family.products?.filter(p => p.comercioExt === true)
                        .map((product, index) => (
                            <ProductCatalogPage 
                                key={ index } 
                                familyName={ cookie.get('i18next') === 'en' ? family.nameEn : family.nameEs }
                                product={ product } 
                                groupName={ groupName } 
                                styles={ styles } />
                        ))
                    ))
                    :
                    fetchedData.families?.map((family) => (
                        family.products?.filter(p => p.comercioExt === true)
                        .map((product, index) => (
                            <ProductCatalogPage 
                                key={ index } 
                                familyName={ cookie.get('i18next') === 'en' ? family.nameEn : family.nameEs }
                                product={ product } 
                                groupName={ groupName } 
                                styles={ styles } />
                        ))
                    ))
            }
        </Document>
    )
};

export default ProductCatalog;
