import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import Layout from './layouts/Layout';
import CatalogExport from './pages/CatalogExport';
import CatalogNational from './pages/CatalogNational';
import Product from './pages/Product';
import Home from './pages/Home';
import InnerProduct from './pages/InnerProduct';
import News from './pages/News';
import InnerNews from './pages/InnerNews';
import Login from './pages/Login';
import Register from './pages/Register';
import RecoveryPassword from './pages/RecoveryPassword';
import ChangeRecoveryPassword from './pages/ChangeRecoveryPassword';
import ChangePassword from './pages/ChangePassword';
import Contact from './pages/Contact';
import SelectaGroup from './pages/SelectaGroup';
import SelectaCompanies from './pages/SelectaCompanies';
import SelectaDistributions from './pages/SelectaDistributions';
import YerbaMate from './pages/YerbaMate';
import Social from './pages/Social';
import Turismo from './pages/Turismo';
import ComercioExterior from './pages/ComercioExterior';
import Distribuidores from './pages/Distribuidores';
import Recetas from './pages/Recetas';
import Receta from './pages/Receta';
import Logout from './components/Logout';
import Profile from './pages/Profile';
import Error from './pages/Error';
import EditProfile from './pages/EditProfile';

const useLocalStorage = (key, initialValue) => {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState(() => {
        if (typeof window === "undefined") {
            return initialValue;
        }
        try {
            // Get from local storage by key
            const item = window.localStorage.getItem(key);
            // Parse stored json or if none return initialValue
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            // If error also return initialValue
            console.log(error);
            return initialValue;
        }
    });
    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = (value) => {
        try {
            // Allow value to be a function so we have same API as useState
            const valueToStore =
                value instanceof Function ? value(storedValue) : value;
            // Save state
            setStoredValue(valueToStore);
            // Save to local storage
            if (typeof window !== "undefined") {
                window.localStorage.setItem(key, JSON.stringify(valueToStore));
            }
        } catch (error) {
            // A more advanced implementation would handle the error case
            console.log(error);
        }
    };
    return [storedValue, setValue];
}

const App = () => {
    return (
        <Router>
            <Layout>
                <ScrollToTop />
                <Routes>
                    <Route exact index element={<Home />} />
                    <Route exact path="/catalogos/comercio-exterior" element={<CatalogExport />} />
                    <Route exact path="/catalogos/nacional" element={<CatalogNational />} />
                    <Route exact path="/productos/cn/:id/:name/" element={<Product useLocalStorage={ useLocalStorage }/>} />
                    <Route exact path="/productos/cn/:id/:name/:family" element={<Product useLocalStorage={ useLocalStorage } />} />
                    <Route exact path="/productos/ft/:id/:name/" element={<Product useLocalStorage={ useLocalStorage } />} />
                    <Route exact path="/productos/ft/:id/:name/:family" element={<Product useLocalStorage={ useLocalStorage } />} />
                    <Route exact path="/producto/cn/:id" element={<InnerProduct />} />
                    <Route exact path="/producto/ft/:id" element={<InnerProduct />} />
                    <Route exact path="/noticias" element={<News />} />
                    <Route exact path="/noticia/:type/:id" element={<InnerNews />} />
                    <Route exact path="/iniciar-sesion" element={<Login />} />
                    <Route exact path="/contacto" element={<Contact />} />
                    <Route exact path="/registrate" element={<Register />} />
                    <Route exact path="/perfil" element={<Profile />} />
                    <Route exact path="/editar-perfil" element={<EditProfile />} />
                    <Route exact path="/recuperar-contrasena" element={<RecoveryPassword />} />
                    <Route exact path="/cambiar-contrasena" element={<ChangePassword />} />
                    <Route exact path="/resetear-contrasena/:token" element={<ChangeRecoveryPassword />} />
                    <Route exact path="/nosotros/grupo-selecta" element={<SelectaGroup />} />
                    <Route exact path="/nosotros/empresa" element={<SelectaCompanies />} />
                    <Route exact path="/nosotros/red-de-distribucion" element={<SelectaDistributions />} />
                    <Route exact path="/yerba-mate" element={<YerbaMate />} />
                    <Route exact path="/social" element={<Social />} />
                    <Route exact path="/turismo" element={<Turismo />} />
                    <Route exact path="/comercio-exterior" element={<ComercioExterior />} />
                    <Route exact path="/distribuidores" element={<Distribuidores />} />
                    <Route exact path="/recetas" element={<Recetas />} />
                    <Route exact path="/logout" element={<Logout />} />
                    <Route exact path="/recetas/:id" element={<Receta />} />
                    <Route path="*" element={<Error />} />
                </Routes>
            </Layout>
        </Router>
    )
}

export default App;
