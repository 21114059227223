import React, {useMemo, useEffect, useState } from 'react';
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import cookies from 'js-cookie';
import Distribuidor from '../components/Distribuidor';
import Loading from '../components/Loading';
import cookie from 'js-cookie';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import config from '../config/config.json';
import { useMediaQuery } from 'react-responsive';

const Distribuidores = () => {
    document.title = cookie.get('i18next') == 'en' ? "Foreign distribution network - Selecta S.A" : "Distribuidores comercio exterior - Selecta S.A";
    const isMobileToTablet = useMediaQuery({ minWidth: '20em', maxWidth: '46.24em' });
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        let ignore = false;

        async function getData() {
            await axios
            .get(config.SERVER_URL + config.GET_EXT_DISTRIBUITORS)
            .then((response) => {
                // handle success
            if(!ignore) {
                    setData(response.data.data);
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .then(() => {
                setLoading(false);
            });
        }

        getData();

        return () => {
            ignore = true;
        }
        }, []);

    return (
        <>
            { isMobileToTablet ?
                <img src={
                    cookie.get('i18next') == 'en' ?
                        require('../images/distribuidores_title_mobile_en.png') :
                        require('../images/distribuidores_title_mobile.png')
                } className="imageSection" />
                :
                <img src={
                    cookie.get('i18next') == 'en' ?
                        require('../images/distribuidores_title_en.png') :
                        require('../images/distribuidores_title.png')
                } className="imageSection" />
            }

            <div class="bodyContainer">

                <div className="block-spacing bread-content">
                    <h2 data-aos="fade-up" className="greenTitle text-center">
                        { cookie.get('i18next') == 'en' ? "Active Importers" : "Directorio de Importadores Activos" }
                    </h2>
                    { !loading ?
                        <div class="distribuidores-wrapper">
                            { data && (
                                data.map((distributor,key) => (
                                    <Distribuidor key={key} information={ distributor } />
                                ))
                            )}
                        </div>
                        :
                        <Loading />
                    }
                </div>
            </div>
        </>
    )
}

export default Distribuidores;
