import { React, useState, useReducer, useEffect } from 'react';
import ProductFilters from '../components/ProductFilters';
import ProductGrids from '../components/ProductGrids';
import config from '../config/config.json';
import axios from 'axios';
import Loading from '../components/Loading';
import { useNavigate, useLocation } from 'react-router-dom';
import cookie from 'js-cookie';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import ProductCatalog from '../components/ProductCatalog';
import { PDFDownloadLink } from '@react-pdf/renderer';

const Product = () => {
    let groupName = decodeURIComponent(window.location.href.split('/')[6]);

    //Update group name.
    sessionStorage.setItem('groupName', decodeURIComponent(window.location.href.split('/')[6]));

    let tradeType = window.location.href.split('/')[4];
    let navigate = useNavigate();
    let location = useLocation();
    const { t } = useTranslation();

    let today = new Date();
    let todaysMonth = String(today.getMonth() + 1).padStart(2, '0');
    let todaysYear = today.getFullYear();

    document.title = groupName + " - Selecta S.A";

    const isMobileToTablet = useMediaQuery({ minWidth: '20em', maxWidth: '46.24em' });
    const [isLoading, setIsLoading] = useState(false);
    const [event, updateEvent] = useReducer((prev, next) => {
        return { ...prev, ...next }
    }, { 
            selectedFamily: '', 
            selectedGroup: '', 
            tradeType: '', 
        });

    const [groups, setGroups] = useState([]);
    const [families, setFamilies] = useState([]);
    const [banners, setBanners] = useState([]);

    useEffect(() => {
        //Clean up states first.
        updateEvent({ selectedGroup: '', selectedFamily: '' });
        let splitted = location.pathname.split('/');

        //Update tradeType state.
        sessionStorage.setItem('tradeType', splitted[2]);
        updateEvent({ tradeType: splitted[2] });

        //Update group state.
        updateEvent({ selectedGroup: parseInt(splitted[3]) });

        //Check if family is present.
        if(splitted[5] !== undefined) {
            sessionStorage.setItem('selectedFamily', splitted[5]);
            updateEvent({ selectedFamily: splitted[5] });
        }
        else {
            sessionStorage.removeItem('selectedFamily');
        }

    }, [location]);

    useEffect(() => {
        setIsLoading(true);
        let ignore = false;

        function fetchData() {
            axios
                .get(config.SERVER_URL + config.GET_ALL_PRODUCTS_GROUPS)
                .then((response) => {
                    if (!ignore) {
                        response.data.data.map((group) => {
                            if(group.id === event.selectedGroup) {
                                let featuredProducts = [];
                                let familiesPerTradeType = [];

                                if(event.tradeType == 'cn') {
                                    group.families?.map(family => {
                                        family.products?.filter(p => p.comercioNac === true).map(product => {
                                            familiesPerTradeType.push(family);
                                        });
                                    });

                                    let uniqueFamiliesPerTradeType = [...new Set(familiesPerTradeType)];
                                    setFamilies(uniqueFamiliesPerTradeType);
                                }

                                else {
                                    group.families?.map(family => {
                                        family.products?.filter(p => p.comercioExt === true).map(product => {
                                            familiesPerTradeType.push(family);
                                        });
                                    });

                                    let uniqueFamiliesPerTradeType = [...new Set(familiesPerTradeType)];
                                    setFamilies(uniqueFamiliesPerTradeType);
                                }

                                setGroups(group);
                            }
                        })
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .then(() => {
                    setIsLoading(false)
                })
        }

        fetchData();
        navigateUrl();

        return () => {
            ignore = true;
        }
        // If there are changes in selectedGroup, family and tradetype, it will re-render and show it.
    }, [event.selectedGroup, event.selectedFamily, event.tradeType]);

    // Group Banner API
    useEffect(() => {
        let ignore = false;
        axios.get(config.SERVER_URL + config.GET_GROUP_BANNER + event.selectedGroup)
            .then((response) => {
                if(!ignore) {
                    let bannerData = response.data.data;
                    setBanners(bannerData);
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .then(() => {
            })

        return () => {
            ignore = true;
        }

    }, [event.selectedGroup]);


    const checkUrl = () => {
        let url = window.location.href.split('/');
        let currentPage = "";
        currentPage = "/"+url[3]+"/"+url[4]+"/"+url[5]+"/"+url[6];

        return currentPage;
    }

    const changeProducts = (family) => {
        updateEvent({ selectedFamily: family });
    }

    const navigateUrl = () => {
        //Check if only group exists in the URL
        if(event.selectedFamily == '' && event.selectedGroup !== '') {
            navigate(checkUrl());
        }

        //Check if both group and family exists in the URL
        if(event.selectedFamily !== '' && event.selectedGroup !== '') {
            navigate(checkUrl()+`/${event.selectedFamily}`);
        }
    }

    const productCount = () => {
        let count = 0;
        if(groups) {
            sessionStorage.setItem('groups', JSON.stringify(groups));

            if(groups.families) {
                if(event.selectedFamily !== '') {
                    groups.families
                        .filter(f => f.id === parseInt(event.selectedFamily))
                        .map(family => {
                            if(event.tradeType === "cn") {
                                family.products?.filter(p => p.comercioNac === true).map(product => {
                                    count = count + 1;
                                });
                            }
                            else {
                                family.products?.filter(p => p.comercioExt === true).map(product => {
                                    count = count + 1;
                                });
                            }
                        });
                }

                else {
                    groups.families
                        .map(family => {
                            if(event.tradeType === "cn") {
                                family.products?.filter(p => p.comercioNac === true).map(product => {
                                    count = count + 1;
                                });
                            }
                            else {
                                family.products?.filter(p => p.comercioExt === true).map(product => {
                                    count = count + 1;
                                });
                            }
                        });
                }
            }
        }

        return count;
    }

    return (
        <>
            { banners ?
                banners?.length > 0 && (
                    <>
                        { banners.map(banner => (
                            isMobileToTablet ?
                                <img 
                                    src={`https://panel-selecta.creadores.io/assets/${cookie.get('i18next') === 'en' ? banner.imagen_mobile_en : banner.imagen_mobile}`}
                                    className="w-100 d-block mb-3" />
                                :
                                <img 
                                    src={`https://panel-selecta.creadores.io/assets/${cookie.get('i18next') === 'en' ? banner.imagen_desktop_en : banner.imagen_desktop}`}
                                    className="w-100 d-block mb-3" />
                        ))}
                    </>
                )
                : null
            }

            <div className={ banners?.length > 0 ? "bodyContainer bread-content mt-0" : "bodyContainer bread-content mt-7" }>


                <ProductFilters
                    families={ families }
                    selectedFamily={ event.selectedFamily }
                    changeProducts={ changeProducts }
                    totalProducts = { productCount }
                />

                { !isLoading ?
                    <>

                        <PDFDownloadLink 
                            document={ <ProductCatalog /> }
                            style={{ textDecoration: 'none', color: '#fff', 
                                backgroundColor: '#00671f', padding: '10px 20px', 
                                borderRadius: '5px', display: 'block', marginBottom: '20px', textAlign: 'center', 
                                width: 'fit-content' }}

                            fileName={ `Catalogo-Selecta-${todaysMonth}-${todaysYear}.pdf` }
                        >
                            {({ blob, url, loading, error }) =>
                                loading ? 'Cargando catálogo...' : 'Descargar catálogo'
                            }
                        </PDFDownloadLink>

                        { event.selectedFamily !== '' ?
                            <div className="productGridWrapper mt-4">
                                { event.tradeType === "cn" ?
                                    groups.families?.filter(f => f.id === parseInt(event.selectedFamily))
                                    .map(family => (
                                        family.products?.filter(product => product.comercioNac === true).map((product, key) => (
                                            <ProductGrids
                                                key={key}
                                                product={ product }
                                                tradeType={ tradeType }
                                                groupName={ groupName }
                                                familyName={ cookie.get('i18next') === 'en' ? family.nameEn : family.nameEs } />
                                        ))
                                    ))
                                    :
                                    groups.families?.filter(f => f.id === parseInt(event.selectedFamily))
                                    .map(family => (
                                        family.products?.filter(product => product.comercioExt === true).map((product, key) => (
                                            <ProductGrids
                                                key={key}
                                                product={ product }
                                                tradeType={ tradeType }
                                                groupName={ groupName }
                                                familyName={ cookie.get('i18next') === 'en' ? family.nameEn : family.nameEs } />
                                        ))
                                    ))

                                }
                            </div>
                            :
                            <div className="productGridWrapper">
                                { event.tradeType == "cn" ?
                                    groups.families?.map((family) => (
                                        family.products?.filter(product => product.comercioNac === true).map((product) => (
                                            <ProductGrids
                                                product={ product }
                                                tradeType={ tradeType }
                                                groupName={ groupName }
                                                familyName={ cookie.get('i18next') === 'en' ? family.nameEn : family.nameEs } />
                                        ))
                                    ))

                                    :
                                    groups.families?.map((family) => (
                                        family.products?.filter(product => product.comercioExt === true).map((product) => (
                                            <ProductGrids
                                                product={ product }
                                                tradeType={ tradeType }
                                                groupName={ groupName }
                                                familyName={ cookie.get('i18next') === 'en' ? family.nameEn : family.nameEs } />
                                        ))
                                    ))
                                }

                            </div>
                        }
                    </>
                    :
                    <Loading />
                }

            </div>
        </>
    )
}

export default Product;
