import React from 'react';
import whatsapp from '../images/whatsapp.svg';


function WhatsappIcon() {
	return (
		<div className="whatsapp-button">
			<a href="https://wa.me/+595982700116" target="_blank">
				<img
					src={whatsapp}
					alt="Whatsapp"
					 />
			</a>
		</div>
	)
}

export default WhatsappIcon;
