import React, { useState } from "react";
import cookie from 'js-cookie';
import axios from 'axios';
import config from '../config/config.json';
import AlertMessage from "../components/AlertMessage";
import Spinner from 'react-bootstrap/Spinner';
import AlertToastMessages from "../components/AlertToastMessages";

const RecoveryPassword = () => {
	document.title = cookie.get('i18next') === 'en' ? "Forgor my password - Selecta S.A." : "He olvidado mi contraseña - Selecta S.A";

	const [showToast, setShowToast] = useState(false);
	const [email, setEmail] = useState("");
	const [variant, setVariant] = useState(null);
	const [message, setMessage] = useState(null);
	const [alertShow, setAlertShow] = useState(false);
	const [checking, setChecking] = useState(false);

	const handleCloseAlert = (show) => {
		setAlertShow(show);
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		setChecking(true);
		let ignore = false;

		axios.post(config.SERVER_URL + config.POST_FORGOT_PASSWORD, {
				email: email
			})
			.then((response) => {
				if (!ignore) {
					console.log(response.data);
					if(response.data.status === "error") {
						setVariant("danger");
						setMessage(response.data.message);
						setAlertShow(true);
						setChecking(false);
					}

					if(response.data.status === "success") {
						setChecking(false);
						setShowToast(true);
                        setEmail("");
					}
				}
			})
			.catch((error) => {
				console.log(error);
				setVariant("danger");
				setMessage(error.message);
				setAlertShow(true);
				setChecking(false);
			})
			.then(() => {
				ignore = true;
		})
	}

	const handleEmail = (event) => {
		setEmail(event.target.value);
	}

	return (
		<>
			{ alertShow && (
				<AlertMessage
					variant={ variant }
					message={ message }
					showMessage={ alertShow }
					handleShow={ handleCloseAlert } />
			)}

			<AlertToastMessages
				show={showToast}
				setShow={setShowToast}
				message={ cookie.get('i18next') === 'en' ? "Email sent successfully!" : "Correo enviado con éxito!" }/>

			<section className="userSection">
				{ cookie.get('i18next') === 'en' ?
					<>
						<h1 data-aos="fade-down">Forgot my password</h1>
						<p data-aos="fade-down">Enter the email address you used for registration, you will receive an email with a link to recover your password.
							<strong> For security reasons, please do not share this email with anyone.</strong></p>
					</>
					:
					<>
						<h1 data-aos="fade-down">He olvidado mi contraseña</h1>
						<p data-aos="fade-down">Ingresa tu correo electrónico con el que te registrate, recibirás un correo con un link
							para recuperar tu contraseña. <strong>Por seguridad, no compartas con nadie dicho correo.</strong>
						</p>
				</>
				}

				<form
                    data-aos="fade-up"
					onSubmit={(e) => { handleSubmit(e) }}>
					<div className="mb-3">
						<label for="userEmail" className="form-label">Email</label>
						<input
							type="email"
							className="form-control"
							id="userEmail"
							aria-describedby="emailHelp"
							autoComplete="username"
							onChange={(event) => { handleEmail(event) }} />
					</div>

					<button type="submit" className="btn btnFormLogin"
						disabled={ checking ? true : false }
						>
						{ !checking ?
							cookie.get('i18next') === 'en' ? "Send email" : "Enviar correo"
							:
							<>
								<Spinner
									as="span"
									animation="border"
									size="sm"
									role="status"
									aria-hidden="true"
									style={{ marginRight: "10px" }}
								/>
								{ cookie.get('i18next') === 'en' ? "Verifying..." : "Verificando..."  }
						</>
						}
					</button>
				</form>
			</section>
		</>
	)
}

export default RecoveryPassword;
