import React from 'react';
import cookie from 'js-cookie';

const Presentations = ({ presentations }) => {
	return (
		<ul className="inner-product-ul">
			{ presentations.map((pres, index) => (
				<li key={ index+pres.id }><span className="pres-var-product">
					{ cookie.get('i18next') == 'en' ? pres.nameEn : pres.nameEs }
				</span></li>
			))}
		</ul>
	)
}

const Varieties = ({ presentations }) => {
	return (
		<ul className="inner-product-ul">
			{ presentations.map(pres => (
				 pres.varieties.map((variedad,index) => (
					<li key={ index+variedad.id }><span className="pres-var-product">
						{ cookie.get('i18next') == 'en' ? variedad.nameEn : variedad.nameEs }
					</span></li>
				))
			))}
		</ul>
	)
}

const ProductPresentation = ({ presentations }) => {
	return (
		<>
			<Presentations presentations={ presentations } />
			<h2 className="title-inner-product mt-5">
				{ cookie.get('i18next') == 'en' ? "VARIETIES": "VARIEDADES" }
			</h2>
			<Varieties presentations={ presentations } />
		</>
	)
}

export default ProductPresentation;
