import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import cookie from 'js-cookie';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import '@splidejs/react-splide/css/core';
import SplideImages from '../components/SplideImages';
import YerbaProperties from '../components/YerbaProperties';
import lonelyLeaf from '../images/leaf.png';
import { useMediaQuery } from 'react-responsive';

const YerbaMate = () => {
	document.title = "Yerba Mate - Selecta S.A.";
    const isMobileToTablet = useMediaQuery({ minWidth: '20em', maxWidth: '46.24em' });
	const { t } = useTranslation();

	const yerba_mate = [
		require("../images/yerba_mate/slider_1.jpg"),
		require("../images/yerba_mate/slider_2.jpg"),
		require("../images/yerba_mate/slider_3.jpg"),
		require("../images/yerba_mate/slider_4.jpg"),
		require("../images/yerba_mate/slider_7.jpg"),
		require("../images/yerba_mate/slider_8.jpg"),
		require("../images/yerba_mate/slider_9.jpg"),
		require("../images/yerba_mate/slider_10.jpg"),
		require("../images/yerba_mate/slider_11.jpg"),
		require("../images/yerba_mate/slider_12.jpg"),
		require("../images/yerba_mate/slider_13.jpg"),
		require("../images/yerba_mate/slider_14.jpg"),
		require("../images/yerba_mate/slider_15.jpg"),
		require("../images/yerba_mate/slider_16.jpg")
	]

	const bella_vista = [
		require('../images/bella_vista/bella_vista_1.png'),
		require('../images/bella_vista/bella_vista_2.png'),
		require('../images/bella_vista/bella_vista_3.jpg'),
		require('../images/bella_vista/bella_vista_4.jpg')
	]

	const [state, setState] = useState({
		photoIndex: 0,
		isOpen: false
	})

	return (
		<>
            { isMobileToTablet ?
                <img src={
                cookie.get('i18next') == 'en' ?
                    require('../images/yerba_mate_title_mobile_en.png') :
                    require('../images/yerba_mate_title_mobile.png')
                } className="imageSection" />
                :
                <img src={
                    cookie.get('i18next') == 'en' ?
                        require('../images/yerba_mate_title.png') :
                        require('../images/yerba_mate_title.png')
                } className="imageSection" />
            }

			<div className="bodyContainer mt-0">

                <div className="index-content-wrapper mb-0">
                    <h2 data-aos="fade-up" className="greenTitle mb-3 text-center title-mobile-left">{ t('ym_history_title') }</h2>
                    <div className="index-grid-content bread-content two-block-text-wrapper align-items-center">
                        <div data-aos="fade-right" className="grey-light-text text-in-blocks">
                            { cookie.get('i18next') === 'en' ?
                                <p>
                                    The Yerba mate’s scientific name is <i>Ilex paraguariensis</i> because it belongs to the lands of the basins of Alto Parana and some tributaries of Paraguay, more specifically in the region of Mbaracayú. The French botanic Auguste Saint-Hilaire gave this name in 1823 in his research trips dedicated to plants.
                                </p>
                            :
                                <p>
                                    La planta de la yerba mate lleva el nombre científico latino de <i>Ilex paraguariensis</i> por ser oriunda de tierras paraguayas de las cuencas del Alto Paraná y algunos afluentes del Paraguay, más concretamente en la región de Mbaracayú. Este nombre le fue dado en 1823 por el botánico francés Auguste de Saint-Hilaire (1779-1853), que en sus viajes de investigación se dedicó especialmente a las plantas.
                                </p>
                            }
                        </div>

                        <img data-aos="fade-left" src={ require('../images/yerba_mate_1.png') } className="yerba-mate-img-block" />
                    </div>
                    
                    <div className="index-grid-content bread-content two-block-text-wrapper align-items-center">
                        <img data-aos="fade-right" src={ require('../images/yerba_mate_2.png') } className="yerba-mate-img-block" />

                        <div data-aos="fade-left" className="grey-light-text text-in-blocks">
                            { cookie.get('i18next') === 'en' ?
                                <p>
                                    The generic designation <i>“Ilex”</i> means “Thorny tree.” <i>Paraguariensis</i> is a Latinized demonism that refers to the geographical position, and it means “Paraguayan.
                                </p>
                                :
                                <p>
                                    La designación genérica <i>Ilex</i> significa árbol espinoso. <i>Paraguariensis</i> es un gentilicio latinizado que remite a la procedencia geográfica y significa paraguayo.
                                </p>
                            }
                            <p>
                                { t('ym_p3') }
                            </p>
                        </div>
                    </div>

                    <div className="index-grid-content bread-content two-block-text-wrapper align-items-center">

                        <div data-aos="fade-right" className="grey-light-text text-in-blocks">
                            <p>
                                { t('ym_p4') }
                            </p>
                            <p>
                                { t('ym_p5') }
                            </p>
                            <p>
                                { t('ym_p6') }
                            </p>
                            <p>
                                { t('ym_p12') }
                            </p>
                            <p>
                                { t('ym_plant_p13') }
                            </p>
                        </div>
                        <img data-aos="fade-left" src={ require('../images/yerba_mate_3.png') } className="yerba-mate-img-block" />
                    </div>
                </div>

                <div className="index-content-wrapper mb-6 bread-content">
                    <h2 data-aos="fade-up" className="light-green-title text-center">{ t('ym_p7') }</h2>
                    <div className="yerba-mate-success-block">
                        <div data-aos="zoom-in" className="yerba-mate-success bg-success-1">
                            <div className="layer-block-orange"></div>
                            <p className="number-success">01.</p>
                            <p className="text-yerba-success">{ t('ym_p8') }</p>
                        </div>
                        <div data-aos="zoom-in" className="yerba-mate-success bg-success-2">
                            <div className="layer-block-orange"></div>
                            <p className="number-success">02.</p>
                            <p className="text-yerba-success">{ t('ym_p9') }</p>
                        </div>
                        <div data-aos="zoom-in" className="yerba-mate-success bg-success-3">
                            <div className="layer-block-orange"></div>
                            <p className="number-success">03.</p>
                           <p className="text-yerba-success">{ t('ym_p10') }</p>
                        </div>
                        <div data-aos="zoom-in" className="yerba-mate-success bg-success-4">
                            <div className="layer-block-orange"></div>
                            <p className="number-success">04.</p>
                           <p className="text-yerba-success">{ t('ym_p11') }</p>
                        </div>
                    </div>
                </div>

                <div className="index-content-wrapper mt-7 mb-0">
                    <div className="index-grid-content bread-content two-block-text-wrapper align-items-center">
                        <div data-aos="fade-right">

                            <h2 className="greenTitle mb-4">{
								cookie.get('i18next') == 'en' ?
								"The \"Yerba\" Lexic"
								:
								"El léxico de la YERBA"
							}</h2>
                            <div className="grey-light-text">
                                <p>{ t('ym_plant_p7') }</p>
                            </div>
                        </div>

                        <div data-aos="fade-left" className="grey-light-text">
                            <p>{ t('ym_plant_p7_1') }</p>
                            <p>{ t('ym_plant_p8') }</p>
                            <p>{ t('ym_plant_p8_1') }</p>
                        </div>
                    </div>
                </div>

                <div className="index-content-wrapper mt-7 position-relative bg-planta-yerba">
                    <div className="black-overlay rounded-0" style={{ zIndex: 0, background: 'rgba(0,0,0,0.6)' }}></div>
                    <div data-aos="fade-up" className='slider-text-yerba-mate'>

                        { cookie.get('i18next') === 'en' ?
                            <h2 className="planta-yerba-mate-title">The Yerba Mate Plant</h2>
                            :
                            <h2 className="planta-yerba-mate-title">La planta de la Yerba Mate</h2>
                        }
                        <Splide
                            options={{
                                perPage: 1,
                                rewind: true,
                                trimSpace: 'move',
                                autoHeight: true,
                                arrows: false
                            }}
                            hasTrack={ false }
                            aria-label={"Slider text"}>

                            <div className="slider-wrapper-text">
                                <SplideTrack>
                                    <SplideSlide>
                                        <p className="slider-text">{ t('ym_plant_p1') }</p>
                                    </SplideSlide>
                                    <SplideSlide>
                                        <p className="slider-text">{ t('ym_plant_p2') }</p>
                                    </SplideSlide>
                                    <SplideSlide>
                                        <p className="slider-text">{ t('ym_plant_p3') } { t('ym_plant_p4') }</p>
                                    </SplideSlide>
                                    <SplideSlide>
                                        <p className="slider-text">{ t('ym_plant_p5') } { t('ym_plant_p6') }</p>
                                    </SplideSlide>
                                </SplideTrack>
                            </div>
                        </Splide>
                    </div>
                </div>
                
                <div data-aos="fade-up" className="bread-content">
                    <SplideImages images={yerba_mate} label="Yerba Mate" />
                </div>
                
                <div className="index-content-wrapper mt-7 mb-5">
                    <div className="index-grid-content bread-content two-block-text-wrapper align-items-start">
                        <div data-aos="fade-right">

                            <p className="mb-0 green-text top-titles">Bella Vista</p>
                            <h2 className="greenTitle mb-4">{
								cookie.get('i18next') == 'en' ?
								"NATIONAL CAPITAL OF YERBA MATE"
								:
								"CAPITAL NACIONAL DE LA YERBA MATE"
							}</h2>

                            <div className="grey-light-text">
                                { cookie.get('i18next') == 'en' ?
                                    <p>The national day of Yerba mate is celebrated on October 11 every year in Paraguay.</p>
                                    :
                                    <p>El día Nacional de la Yerba Mate se celebra el día 11 de octubre de cada año en Paraguay.</p>
                                }
                            </div>
                        </div>

                        <div data-aos="fade-left" className="grey-light-text">
                            <p>{ t('ym_plant_p10') }</p>
                            <p>{ t('ym_plant_p11') }</p>
                            <p>{ t('ym_plant_p12') }</p>
                        </div>
                    </div>
                </div>

                <div data-aos="fade-up" className="bread-content">
                    <SplideImages images={bella_vista} label="Yerba Mate" />
                </div>

                <div className="index-content-wrapper py-3 mt-7 mb-0">
                    <img className="top-bg-design z-1" src={ require('../images/corte_top.png') } />
                    <img className="bottom-bg-design z-1" src={ require('../images/corte_bottom.png') } />

                    <img data-aos="fade-right" className="lonely-left-leaf" src={ lonelyLeaf } />
                    <img data-aos="fade-left" className="lonely-right-leaf" src={ lonelyLeaf } />

                    <YerbaProperties />
                </div>

                <div className="index-content-wrapper bread-content mt-5 mb-5">
					<h2 data-aos="fade-up" className="h2-title-double text-center mb-0 grey-text">{ t('ym_process_title1') }</h2>
					<h2 data-aos="fade-up" className="h2-title-double text-center mt-0 green-text italic-text">{ t('ym_process_title2') }</h2>

                    <div className="process-block-wrapper mt-7">
                        <div data-aos="zoom-in" className="process-block bg-proceso bg-cultivo">
                            <div className="black-overlay"></div>
                            <img src={ require('../images/cultivo.png') } className="process-icon" />
                            <p className="process-title">{ t('ym_process_1_title') }</p>
                            <p className="limit-height-text">{ t('ym_process_1_text1') }</p>
                            <button type="button" class="btn btn-show-more-modal" data-bs-toggle="modal" data-bs-target="#cultivoModal">
                                { t('show_more') }
                            </button>

                        </div>

                        <div data-aos="zoom-in" className="process-block bg-proceso bg-cosecha">
                            <div className="black-overlay"></div>
                            <img src={ require('../images/cosecha.png') } className="process-icon" />
                            <p className="process-title">{ t('ym_process_2_title') }</p>
                            <p className="limit-height-text">{ t('ym_process_2_text1') }</p>
                            <button type="button" class="btn btn-show-more-modal" data-bs-toggle="modal" data-bs-target="#cosechaModal">
                                { t('show_more') }
                            </button>
                        </div>

                        <div data-aos="zoom-in" className="process-block bg-proceso bg-sapecado">
                            <div className="black-overlay"></div>
                            <img src={ require('../images/sapecado.png') } className="process-icon" />
                            <p className="process-title">{ t('ym_process_3_title') }</p>
                            <p className="limit-height-text">{ t('ym_process_3_text1') }</p>
                            <button type="button" class="btn btn-show-more-modal" data-bs-toggle="modal" data-bs-target="#sapecadoModal">
                                { t('show_more') }
                            </button>
                        </div>

                        <div data-aos="zoom-in" className="process-block bg-proceso bg-secado">
                            <div className="black-overlay"></div>
                            <img src={ require('../images/secado.png') } className="process-icon" />
                            <p className="process-title">{ t('ym_process_4_title') }</p>
                            <p className="limit-height-text">{ t('ym_process_4_text1') }</p>
                            <button type="button" class="btn btn-show-more-modal" data-bs-toggle="modal" data-bs-target="#secadoModal">
                                { t('show_more') }
                            </button>
                        </div>

                        <div data-aos="zoom-in" className="process-block bg-proceso bg-estacionado">
                            <div className="black-overlay"></div>
                            <img src={ require('../images/estacionado.png') } className="process-icon" />
                            <p className="process-title">{ t('ym_process_5_title') }</p>
                            <p>{ t('ym_process_5_text1') }</p>
                        </div>

                        <div data-aos="zoom-in" className="process-block bg-proceso bg-molienda">
                            <div className="black-overlay"></div>
                            <img src={ require('../images/molienda.png') } className="process-icon" />
                            <p className="process-title">{ t('ym_process_6_title') }</p>
                            <p className="limit-height-text">{ t('ym_process_6_text1') }</p>
                            <button type="button" class="btn btn-show-more-modal" data-bs-toggle="modal" data-bs-target="#moliendaModal">
                                { t('show_more') }
                            </button>
                        </div>

                        <div data-aos="zoom-in" className="process-block bg-proceso bg-envasado">
                            <div className="black-overlay"></div>
                            <img src={ require('../images/envasado.png') } className="process-icon" />
                            <p className="process-title">{ t('ym_process_7_title') }</p>
                            <p className="limit-height-text">{ t('ym_process_7_text1') }</p>
                            <button type="button" class="btn btn-show-more-modal" data-bs-toggle="modal" data-bs-target="#envasadoModal">
                                { t('show_more') }
                            </button>
                        </div>
                    </div>
                </div>


                <div class="modal fade" id="cultivoModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="cultivoModalLabel" aria-hidden="true">
                    <div class="modal-dialog custom-modal-dialog modal-dialog-centered modal-dialog-scrollable">
                        <div class="modal-content p-0">
                            <div class="modal-body p-0">
                                <div className="process-block-modal bg-proceso bg-cultivo">
                                <button type="button" className="btn-close-modal" data-bs-dismiss="modal" aria-label="Close">X</button>
                                    <div className="black-overlay-modal"></div>
                                    <img src={ require('../images/cultivo.png') } className="process-icon" />
                                    <p className="process-title-modal">{ t('ym_process_1_title') }</p>
                                    <p>{ t('ym_process_1_text1') }</p>
                                    <p>{ t('ym_process_1_text2') }</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal fade" id="cosechaModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="cosechaModalLabel" aria-hidden="true">
                    <div class="modal-dialog custom-modal-dialog modal-dialog-centered modal-dialog-scrollable">
                        <div class="modal-content p-0">
                            <div class="modal-body p-0">
                                <div className="process-block-modal bg-proceso bg-cosecha">
                                <button type="button" className="btn-close-modal" data-bs-dismiss="modal" aria-label="Close">X</button>
                                    <div className="black-overlay-modal"></div>
                                    <img src={ require('../images/cosecha.png') } className="process-icon" />
                                    <p className="process-title-modal">{ t('ym_process_2_title') }</p>
                                    <p>{ t('ym_process_2_text1') }</p>
                                    <p>{ t('ym_process_2_text2') }</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal fade" id="sapecadoModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="sapecadoModalLabel" aria-hidden="true">
                    <div class="modal-dialog custom-modal-dialog modal-dialog-centered modal-dialog-scrollable">
                        <div class="modal-content p-0">
                            <div class="modal-body p-0">
                                <div className="process-block-modal bg-proceso bg-sapecado">
                                <button type="button" className="btn-close-modal" data-bs-dismiss="modal" aria-label="Close">X</button>
                                    <div className="black-overlay-modal"></div>
                                    <img src={ require('../images/sapecado.png') } className="process-icon" />
                                    <p className="process-title-modal">{ t('ym_process_3_title') }</p>
                                    <p>{ t('ym_process_3_text1') }</p>
                                    <p>{ t('ym_process_3_text2') }</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal fade" id="secadoModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="secadoModalLabel" aria-hidden="true">
                    <div class="modal-dialog custom-modal-dialog modal-dialog-centered modal-dialog-scrollable">
                        <div class="modal-content p-0">
                            <div class="modal-body p-0">
                                <div className="process-block-modal bg-proceso bg-secado">
                                <button type="button" className="btn-close-modal" data-bs-dismiss="modal" aria-label="Close">X</button>
                                    <div className="black-overlay-modal"></div>
                                    <img src={ require('../images/secado.png') } className="process-icon" />
                                    <p className="process-title-modal">{ t('ym_process_4_title') }</p>
                                    <p>{ t('ym_process_4_text1') }</p>
                                    <p>{ t('ym_process_4_text2') }</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal fade" id="moliendaModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="moliendaModalLabel" aria-hidden="true">
                    <div class="modal-dialog custom-modal-dialog modal-dialog-centered modal-dialog-scrollable">
                        <div class="modal-content p-0">
                            <div class="modal-body p-0">
                                <div className="process-block-modal bg-proceso bg-molienda">
                                <button type="button" className="btn-close-modal" data-bs-dismiss="modal" aria-label="Close">X</button>
                                    <div className="black-overlay-modal"></div>
                                    <img src={ require('../images/molienda.png') } className="process-icon" />
                                    <p className="process-title-modal">{ t('ym_process_6_title') }</p>
                                    <p>{ t('ym_process_6_text1') }</p>
                                    <p>{ t('ym_process_6_text2') }</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal fade" id="envasadoModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="envasadoModalLabel" aria-hidden="true">
                    <div class="modal-dialog custom-modal-dialog">
                        <div class="modal-content p-0">
                            <div class="modal-body p-0">
                                <div className="process-block-modal bg-proceso bg-envasado">
                                <button type="button" className="btn-close-modal" data-bs-dismiss="modal" aria-label="Close">X</button>
                                    <div className="black-overlay-modal"></div>
                                    <img src={ require('../images/envasado.png') } className="process-icon" />
                                    <p className="process-title-modal">{ t('ym_process_7_title') }</p>
                                    <p>{ t('ym_process_7_text1') }</p>
                                    <p>{ t('ym_process_7_text2') }</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

				<div className="preparar-mate-wrapper bread-content">
					<h2 data-aos="fade-up" className="grey-text">{ t('ym_prepare_title') }<span className="italic-text"> MATE</span></h2>
					<div className="preparar-steps-wrapper">
						<div data-aos="fade-down" className="preparacion-block">
						<p>01</p>
							<p>
								{ t('ym_prepare_1') }
							</p>
							<img src={ require('../images/yerba_mate/prep1.png') } />
						</div>

						<div data-aos="fade-down" className="preparacion-block">
						<p>02</p>
							<p>
								{ t('ym_prepare_2') }
							</p>
							<img src={ require('../images/yerba_mate/prep2.png') } />
						</div>

						<div data-aos="fade-down" className="preparacion-block">
						<p>03</p>
							<p>
								{ t('ym_prepare_3') }
							</p>
							<img src={ require('../images/yerba_mate/prep3.png') } />
						</div>

						<div data-aos="fade-down" className="preparacion-block">
						<p>04</p>
							<p>
								{ t('ym_prepare_4') }
							</p>
							<img src={ require('../images/yerba_mate/prep4.png') } />
						</div>

						<div data-aos="fade-down" className="preparacion-block">
						<p>05</p>
							<p>
								{ t('ym_prepare_5') }
							</p>
							<img src={ require('../images/yerba_mate/prep5.png') } />
						</div>

						<div data-aos="fade-down" className="preparacion-block">
						<p>06</p>
							<p>
								{ t('ym_prepare_6') }
							</p>
							<img src={ require('../images/yerba_mate/prep6.png') } />
						</div>
					</div>

				</div>

				<div className="formas-yerba-mate-wrapper bread-content">
					<h2 data-aos="fade-up" className="h2-title-double text-center grey-text">{ t('ym_ways_title1') }</h2>
					<h2 data-aos="fade-up" className="h2-title-double text-center green-text italic-text">{ t('ym_ways_title2') }</h2>

                    <div data-aos="fade-up" className="preparados-center-wrapper">
                        <Splide
                            options={{
                                perPage: 1,
                                rewind: true,
                                pagination: false,
                                trimSpace: 'move',

                            }}
                            hasTrack={ false }
                            aria-label={"Slider text"}>

                            <div className="splide__arrows">
                                <button className="splide__arrow splide__arrow--prev arrow-slider-wrapper">
                                    <img src={ require('../images/arrow_left.png') } className="arrow-left-slider" />
                                </button>

                                <button className="splide__arrow splide__arrow--next arrow-slider-wrapper">
                                    <img src={ require('../images/arrow_right.png') } className="arrow-right-slider" />
                                </button>
                            </div>

                            <div className="slider-wrapper">
                                <SplideTrack>
                                    <SplideSlide>
                                        <img src={ require('../images/paso_1.png') } className="w-100 d-block mx-auto" />
                                    </SplideSlide>
                                    <SplideSlide>
                                        <img src={ require('../images/paso_2.png') } className="w-100 d-block mx-auto" />
                                    </SplideSlide>
                                    <SplideSlide>
                                        <img src={ require('../images/paso_3.png') } className="w-100 d-block mx-auto" />
                                    </SplideSlide>
                                    <SplideSlide>
                                        <img src={ require('../images/paso_4.png') } className="w-100 d-block mx-auto" />
                                    </SplideSlide>
                                </SplideTrack>
                            </div>
                        </Splide>
                    </div>
				</div>

			</div>
		</>
	)
}

export default YerbaMate;
