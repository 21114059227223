import React, { useEffect, useState } from "react";
import cookie from 'js-cookie';
import axios from 'axios';
import config from '../config/config.json';
import Loading from '../components/Loading';
import AlertMessage from "../components/AlertMessage";
import Spinner from 'react-bootstrap/Spinner';
import AlertToastMessages from "../components/AlertToastMessages";

const EditProfile = () => {
	document.title = cookie.get('i18next') === 'en' ? "Edit Profile - Selecta S.A." : "Editar Perfil - Selecta S.A.";

	const initialState = {
		firstName: { data: '', error: '' },
		lastName: { data: '', error: '' },
		address: { data: '', error: '' },
		email: { data: '', error: '' },
		phone: { data: '', error: '' }
	}

	const [showToast, setShowToast] = useState(false);
	const [userInfo, setUserInfo] = useState(null);
	const [inputs, setInputs] = useState(initialState);

	const [loading, setLoading] = useState(false);
	const [variant, setVariant] = useState(null);
	const [message, setMessage] = useState(null);
	const [alertShow, setAlertShow] = useState(false);
	const [checking, setChecking] = useState(false);

	const handleInputChange = (event) => {
		const { name, value } = event.target;

		if(value !== '') {
			setInputs(prevState => { return { ...prevState, [name]: { error: '' }}});
		}

		setInputs({ ...inputs, [name] : { data: value }});
	}

	function handleNumbers(event) {
		const reg = /^[0-9\b]+$/;
		if(event.target.value === '' || reg.test(event.target.value)) {
			setInputs({ ...inputs, [event.target.name]: { data: event.target.value }});
		}
	}

	useEffect(() => {
		setLoading(true);
		let ignore = false;

		async function getUserInfo() {
			await axios
			.get(config.SERVER_URL + config.GET_USER_INFO, {
                headers: { "Authorization": `Bearer ${cookie.get('token')}` }
            })
			.then((response) => {
				// handle success
				if(!ignore) {
					console.log(response);
					setUserInfo(response.data.data);
				}
			})
			.catch((error) => {
				console.log(error);
			})
			.then(() => {
				setLoading(false);
			});
		}

		getUserInfo();

		return () => {
			ignore = true;
		}
		}, []);

	useEffect(() => {
		if(userInfo) {
			setInputs(prevState => { return { ...prevState, firstName: {
				data: userInfo.firstName
			}}});

			//setInputs(inputs => (inputs.firstName.data = userInfo.firstName, inputs));
			setInputs(inputs => (inputs.lastName.data = userInfo.lastName, inputs));
			setInputs(inputs => (inputs.email.data = userInfo.email, inputs));
			setInputs(inputs => (inputs.address.data = userInfo.address, inputs));
			setInputs(inputs => (inputs.phone.data = userInfo.telephone, inputs));
		}
		}, [userInfo]);

	const handleCloseAlert = (show) => {
		setAlertShow(show);
	}

	const handleSubmit = (e) => {
		e.preventDefault();

		setChecking(true);

		let ignore = false;
		let validating = true;
		let errorFound = false;


		if(validating) {
			if(inputs.firstName.data == '') {
				setInputs(prevState => { return { ...prevState, firstName: {
					error: cookie.get('i18next') == 'en' ? "Name field is required" : "Debes completar el campo de Nombre"
				}}});
				errorFound = true;
				setChecking(false);
			}

			if(inputs.lastName.data == '') {
				setInputs(prevState => { return { ...prevState, lastName: {
					error: cookie.get('i18next') == 'en' ? "Last name field is required" : "Debes completar el campo de Apellido"
				}}});
				errorFound = true;
				setChecking(false);
			}

			if(inputs.email.data == '') {
				setInputs(prevState => { return { ...prevState, email: {
					error: cookie.get('i18next') == 'en' ? "Email field is required" : "Debes completar el campo de Correo Electrónico"
				}}});
				errorFound = true;
				setChecking(false);
			}

			if(inputs.phone.data == '') {
				setInputs(prevState => { return { ...prevState, phone: {
					error: cookie.get('i18next') == 'en' ? "Phone field is required" : "Debes completar el campo de Teléfono"
				}}});
				errorFound = true;
				setChecking(false);
			}

			if(!errorFound) {
				validating = false;
			}
		}

		if(!validating) {
			axios.patch(config.SERVER_URL + config.PUT_UPDATE_USER, {
				firstName: inputs.firstName.data,
				lastName: inputs.lastName.data,
				email: inputs.email.data,
				telephone: inputs.phone.data,
				address: inputs.address.data
				}, {
                    headers: { "Authorization": `Bearer ${cookie.get('token')}`},
                })
				.then((response) => {
					if (!ignore) {
						if(response.data.status === "error") {
							console.log(response.data);
							setVariant("danger");
							setMessage(response.data.message);
							setAlertShow(true);
							setChecking(false);
						}

						if(response.data.status === "success") {
							setShowToast(true);
							setChecking(false);
						}
					}
				})
				.catch((error) => {
					console.log(error);
				})
				.then(() => {
					ignore = true;
			})
		}
	}

	return (
		<>
			{ alertShow && (
				<AlertMessage
					variant={ variant }
					message={ message }
					showMessage={ alertShow }
					handleShow={ handleCloseAlert } />
			)}

			<AlertToastMessages
				show={showToast}
				setShow={setShowToast}
				message={ cookie.get('i18next') == 'en' ? "Profile edited successfully!" : "Perfil editado con éxito!" }/>

				<section className="userSection">
					<h1 className="mb-4">{ cookie.get('i18next') == 'en' ? "EDIT PROFILE" : "EDITAR PERFIL" }</h1>

				{ !loading ?
					<form
						className="formUser"
						onSubmit={(e) => { handleSubmit(e) }}>

						<div className="mb-3"
							>
							<label for="userName" className="form-label">
								{ cookie.get('i18next') == 'en' ? "Name *" : "Nombre *" }
							</label>

							<input
								type="text"
								className="form-control"
								id="userName"
								name="firstName"
								value={ inputs.firstName.data || '' }
								onChange={ handleInputChange } />

							<span className="span-error-message">{ inputs.firstName.error !== '' ? inputs.firstName.error : '' }</span>
						</div>

						<div className="mb-3">
							<label for="userLastName" className="form-label">
								{ cookie.get('i18next') === 'en' ? "Last Name *" : "Apellido *" }
							</label>

							<input
								type="text"
								className="form-control"
								id="userLastName"
								name="lastName"
								value={ inputs.lastName.data || '' }
								onChange={ handleInputChange } />

							<span className="span-error-message">{ inputs.lastName.error !== '' ? inputs.lastName.error : '' }</span>
						</div>

						<div className="mb-3">
							<label for="userEmail" className="form-label">Email *</label>

							<input
								type="email"
								className="form-control"
								id="userEmail"
								name="email"
								value={ inputs.email.data || '' }
								onChange={ handleInputChange } />

							<span className="span-error-message">{ inputs.email.error !== '' ? inputs.email.error : '' }</span>
						</div>

						<div className="mb-3">
							<label for="userAddress" className="form-label">
								{ cookie.get('i18next') === 'en' ? "Address" : "Dirección" }
							</label>

							<input
								type="text"
								className="form-control"
								id="userAddress"
								name="address"
								value={ inputs.address.data || '' }
								onChange={ handleInputChange } />
						</div>

						<div className="mb-3">
							<label for="userPhone" className="form-label">
								{ cookie.get('i18next') === 'en' ? "Phone / Cellphone" : "Teléfono / Celular" }
							</label>

							<input
								type="text"
								className="form-control"
								id="userPhone"
								name="phone"
								value={ inputs.phone.data }
								onChange={ handleNumbers } />
						</div>

						<button
							type="submit"
							className="btn btnFormLogin"
							disabled={ checking ? true : false }>
							{ !checking ?
								cookie.get('i18next') === 'en' ? "Edit profile" : "Editar perfil"
								:
								<>
									<Spinner
										as="span"
										animation="border"
										size="sm"
										role="status"
										aria-hidden="true"
										style={{ marginRight: "10px" }}
									/>
									{ cookie.get('i18next') == 'en' ? "Verifying..." : " Verificando..."  }
							</>
							}
						</button>
					</form>
					:
					<Loading />
				}
				</section>
		</>
	)
}

export default EditProfile;
