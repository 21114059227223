import React from 'react'
import { useTranslation } from 'react-i18next';
import SplideImages from '../components/SplideImages';
import { useMediaQuery } from 'react-responsive';
import cookie from 'js-cookie';

const Social = () => {
	document.title = "Social - Selecta S.A";
    const isMobileToTablet = useMediaQuery({ minWidth: '20em', maxWidth: '46.24em' });
	const { t } = useTranslation();

	const social_images = [
		require('../images/social/image1.png'),
		require('../images/social/image2.png'),
		require('../images/social/image3.png'),
		require('../images/social/image4.png'),
		require('../images/social/image5.png'),
	]

	return (
		<>
            { isMobileToTablet ?
                <img src={
                cookie.get('i18next') == 'en' ?
                    require('../images/social_title_mobile_en.png') :
                    require('../images/social_title_mobile.png')
                } className="imageSection" />
                :
                <img src={require('../images/social_title.png')} className="imageSection" />
            }

			<div className="bodyContainer mb-0">
                <div className="index-grid-content no-margin-bottom-mobile bread-content two-block-wrapper mt-7 mb-5 align-items-center align-start-mobile gap-mobile">
                    <img data-aos="fade-right" src={ require('../images/social_1.png') } className="w-100 d-block" />

                    <div className="grey-light-text text-in-blocks">
                        <h2 data-aos="fade-left" className="greenTitle mb-4">{ t('social_title') }</h2>
                        <p data-aos="fade-left" className="selecta-group-history-text">
                            { t('social_p1') }
                        </p>
                    </div>
                </div>

                <div className="index-grid-content no-margin-top-mobile bread-content only-one-block two-block-wrapper mt-7 align-items-center">
                    <div className="grey-light-text text-in-blocks">
                        <p data-aos="fade-up" className="selecta-group-history-text">
                            { t('social_p2') }
                        </p>
                        <p data-aos="fade-up" className="selecta-group-history-text">
                            { t('social_p3') }
                        </p>
                        <p data-aos="fade-up" className="selecta-group-history-text">
                            { t('social_p4') }
                        </p>
                    </div>
                    <img data-aos="fade-left" src={ require('../images/social_2.png') } className="w-100 d-block" />
                </div>

                <img data-aos="fade-up" src={ require('../images/ursula_logo.png') } className="ursula-logo" />

                <div className="index-grid-content bread-content two-block-wrapper mt-5 align-items-start">
                    <div className="grey-light-text text-in-blocks">
                        <p data-aos="fade-right" className="selecta-group-history-text">
                            { t('social_fund_p1') }
                        </p>

                        <p data-aos="fade-right" className="selecta-group-history-text">
                            { t('social_fund_p2') }
                        </p>
                    </div>

                    <div className="grey-light-text text-in-blocks">
                        <p data-aos="fade-left" className="selecta-group-history-text">
                            { t('social_fund_p3') }
                        </p>

                        <p data-aos="fade-left" className="selecta-group-history-text">
                            { t('social_fund_p4') }
                        </p>
                    </div>
                </div>

                <div className="bread-content">
                    <SplideImages images={social_images} label="Yerba Mate" />
                </div>
			</div>
		</>
	)
}

export default Social;
