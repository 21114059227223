import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import cookie from 'js-cookie';
import axios from 'axios';
import config from '../config/config.json';
import AlertMessage from "../components/AlertMessage";
import Spinner from 'react-bootstrap/Spinner';
import {useNavigate} from 'react-router-dom';

const Login = () => {
	const navigate = useNavigate();

	useEffect(() => {
		if(cookie.get('ustatus') === "true") {
			navigate('/');
		}
	}, []);

	document.title = cookie.get('i18next') == 'en' ? "Log in - Selecta S.A." : "Iniciar sesión - Selecta S.A.";
	const [email, setEmail] = useState(null);
	const [password, setPassword] = useState(null);
	const [variant, setVariant] = useState(null);
	const [message, setMessage] = useState(null);
	const [alertShow, setAlertShow] = useState(false);
	const [checking, setChecking] = useState(false);

	const handleSubmit = (e) => {
		e.preventDefault();
		let ignore = false;
		setChecking(true);

		axios.post(config.SERVER_URL + config.POST_LOGIN, {
				userMail: email,
				password: password
			})
			.then((response) => {
				if (!ignore) {
					if(response.data.status === "error") {
						setVariant("danger");
						setMessage("Los datos ingresados no pertenecen a un usuario registrado.");
						setAlertShow(true);
						setChecking(false);
					}

                    if(response.data.status === "success") {
                        //Call user info API
                        axios.get(config.SERVER_URL + config.GET_USER_INFO, {
                            headers: {
                                "Authorization": `Bearer ${response.data.data.token}`
                            }
                        })
                            .then((userData) => {
                                if(userData.data.status === "success") {
                                    cookie.set("token", response.data.data.token);
                                    cookie.set("uid", userData.data.data.id);
                                    cookie.set("ustatus", true);
                                    cookie.set("name", userData.data.data.firstName);
                                    cookie.set("lastName", userData.data.data.lastName);
                                    cookie.set("email", userData.data.data.email);
                                    window.location.replace("/");
                                }
                            })
                            .catch((userDataError) => {
                                console.log(userDataError);
                            })
                            .then(() => {
                            })
                    }
				}
			})
			.catch((error) => {
				console.log(error);
			})
			.then(() => {
				ignore = true;
		})
	}

	const handleEmail = (event) => {
		setEmail(event.target.value);
	}

	const handlePassword = (event) => {
		setPassword(event.target.value);
	}

	const handleCloseAlert = (show) => {
		setAlertShow(show);
	}

	return (
		<>
			{ alertShow && (
				<AlertMessage
					variant={ variant }
					message={ message }
					showMessage={ alertShow }
					handleShow={ handleCloseAlert } />
			)}

			<section className="userSection">
			<h1 data-aos="fade-down">{ cookie.get('i18next') == 'en' ? "Log In" : "Iniciar Sesión" }</h1>
				<p data-aos="fade-down">
					{ cookie.get('i18next') == 'en' ? "Don't have an account? " : "¿No tienes una cuenta? " }
					<Link to="/registrate">{ cookie.get('i18next') == 'en' ? "Register" : "Registrate" }</Link>
				</p>

				<form
                    data-aos="fade-up"
					className="formUser"
					onSubmit={(e) => { handleSubmit(e) }}>
					<div className="mb-3">
						<label for="userEmail" className="form-label">E-mail</label>
						<input
							type="email"
							className="form-control"
							id="userEmail"
							aria-describedby="emailHelp"
							autoComplete="username"
							onChange={(event) => { handleEmail(event) }} />
					</div>

					<div className="mb-3">
						<label for="loginPassword" className="form-label">{ cookie.get('i18next') == 'en' ? "Password": "Contraseña" }</label>
						<input
							type="password"
							className="form-control"
							id="loginPassword"
							autoComplete="current-password"
							onChange={(event) => { handlePassword(event) }} />
					</div>

					<button type="submit" className="btn btnFormLogin"
						disabled={ checking ? true : false }
						>
						{ !checking ?
							cookie.get('i18next') == 'en' ? "Log in" : "Iniciar sesión"
							:
							<>
								<Spinner
									as="span"
									animation="border"
									size="sm"
									role="status"
									aria-hidden="true"
									style={{ marginRight: "10px" }}
								/>
								{ cookie.get('i18next') == 'en' ? "Verifying..." : "Verificando..."  }
						</>
						}
					</button>
				</form>

				<div data-aos="fade-up" className="text-center">
					<Link to="/recuperar-contrasena" className="formLink">
						{ cookie.get('i18next') == 'en' ? "Forgot your password?" : "¿Olvidaste tu contraseña?" }
					</Link>
				</div>
			</section>
		</>
	)
}

export default Login;
