import React from 'react';
import { useTranslation } from 'react-i18next';

function YerbaProperties() {
    const { t } = useTranslation();
    return (
        <div data-aos="fade-right" className="background-index-properties mb-0">
            <div className="divided-title-block mb-5">
                <h2 data-aos="fade-down">{ t('home_yerba_mate_properties_title') }</h2>
                <div data-aos="fade-down" dangerouslySetInnerHTML={{ __html: t('yerba_mate_properties_p') }} />
            </div>

            <div data-aos="zoom-in-up" className="properties-wrapper">
                <div className="property-block">
                    <img src={ require("../images/prop1.png") } />
                    { t('antioxidants') }
                </div>

                <div className="property-block">
                    <img src={ require("../images/prop2.png") } />
                    { t('antibacterial') }
                </div>

                <div className="property-block">
                    <img src={ require("../images/prop3.png") } />
                { t('purifying') }
                </div>

                <div className="property-block">
                    <img src={ require("../images/prop4.png") } />
                    { t('rich_vitamins') }
                </div>

                <div className="property-block">
                    <img src={ require("../images/prop5.png") } />
                    { t('reduce_risk') }
                </div>

                <div className="property-block">
                    <img src={ require("../images/prop6.png") } />
                    { t('reduce_absorption') }
                </div>

                <div className="property-block">
                    <img src={ require("../images/prop7.png") } />
                    { t('slow_age') }
                </div>

                <div className="property-block">
                    <img src={ require("../images/prop8.png") } />
                    { t('energizing') }
                </div>
            </div>
        </div>
    )
}

export default YerbaProperties;
