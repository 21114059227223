import Nav from 'react-bootstrap/Nav';
import cookie from 'js-cookie';

const ProductLineButtons = ({ groups, groupSelected, groupId }) => {
    console.log(groups);
	return (
		<>
			<Nav
                data-aos="fade-down"
				className="nav-pills-catalogs"
				variant="pills">

				{ groups.map((group, index) => (
					<Nav.Item>
						<Nav.Link
							onClick={() => { groupSelected (
								cookie.get('i18next') === 'en' ? group.nameEn : group.nameEs,
								group.id
							)}}

							id={ "navLink"+index+group.id }
							className="productLineButton" eventKey={ "navItem"+index }>
							{ cookie.get('i18next') === 'en' ? group.nameEn : group.nameEs }
						</Nav.Link>
					</Nav.Item>
				))}
			</Nav>

		</>
	)
}

export default ProductLineButtons;
