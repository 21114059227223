import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';

function ContactAccordion() {
	return (
		<>
			<Accordion data-aos="fade-right" className="contact-acc" defaultActiveKey="1">
				<Accordion.Item eventKey="1">
					<Accordion.Header className="contact-acc-header">San Lorenzo</Accordion.Header>
					<Accordion.Body>
						<div className="distribuitor-information-block">
							<FontAwesomeIcon icon={ faLocationDot } />
							<a target="_blank" href="https://goo.gl/maps/Dx47xLFgw6h3Vbo97">
								Florida casi Sargento Silva - Barrio Florida – San Lorenzo - Central
							</a>
						</div>

						<div className="distribuitor-information-block">
							<FontAwesomeIcon icon={ faPhone } />
							<p>021 584 011</p>
						</div>
					</Accordion.Body>
				</Accordion.Item>

				<Accordion.Item eventKey="2">
					<Accordion.Header className="contact-acc-header">Yby Yau</Accordion.Header>
					<Accordion.Body>
						<div className="distribuitor-information-block">
							<FontAwesomeIcon icon={ faLocationDot } />
							<a target="_blank" href="https://goo.gl/maps/HWjNsrW7EXktQHzx7">
								Ruta 5 km 111.5 – Yby Yau – Concepción
							</a>
						</div>

						<div className="distribuitor-information-block">
							<FontAwesomeIcon icon={ faPhone } />
							<p>039 210 459</p>
						</div>
					</Accordion.Body>
				</Accordion.Item>

				<Accordion.Item eventKey="3">
					<Accordion.Header className="contact-acc-header">Coronel Oviedo</Accordion.Header>
					<Accordion.Body>
						<div className="distribuitor-information-block">
							<FontAwesomeIcon icon={ faLocationDot } />
							<a target="_blank" href="https://goo.gl/maps/JQsUzZjT9aZRK38L9">
								Ruta 8 km 134 - Barrio José Maria Alfonso Godoy – Cnel. Oviedo - Caaguazú
							</a>
						</div>

						<div className="distribuitor-information-block">
							<FontAwesomeIcon icon={ faPhone } />
							<p>0985 951 277</p>
						</div>
					</Accordion.Body>
				</Accordion.Item>
            
				<Accordion.Item eventKey="4">
					<Accordion.Header className="contact-acc-header">Misiones</Accordion.Header>
					<Accordion.Body>
						<div className="distribuitor-information-block">
							<FontAwesomeIcon icon={ faLocationDot } />
							<a target="_blank" href="https://goo.gl/maps/Gz14VK8fEP5uSfeR7">
								Avda. Florida c/ Isidro Morínigo y Alberdi – Barrio Cristo Rey – Santa Rosa – Misiones
							</a>
						</div>

						<div className="distribuitor-information-block">
							<FontAwesomeIcon icon={ faPhone } />
							<p>0985 133 999</p>
						</div>
					</Accordion.Body>
				</Accordion.Item>


				<Accordion.Item eventKey="5">
					<Accordion.Header className="contact-acc-header">Chaco</Accordion.Header>
					<Accordion.Body>
						<div className="distribuitor-information-block">
							<FontAwesomeIcon icon={ faLocationDot } />
							<a target="_blank" href="https://goo.gl/maps/L37xULVXab9UtkX39">
								Calle Paratodo casi ruta 9 – Barrio Primavera – Filadelfia – Chaco
							</a>
						</div>
					</Accordion.Body>
				</Accordion.Item>


				<Accordion.Item eventKey="6">
					<Accordion.Header className="contact-acc-header">La Saeta - Bella vista</Accordion.Header>
					<Accordion.Body>
						<div className="distribuitor-information-block">
							<FontAwesomeIcon icon={ faLocationDot } />
							<a target="_blank" href="https://goo.gl/maps/DChcUtQxxtFn9SDf7">
								Casa Matriz Avda. Marcial Samaniego km … Barrio Paraíso – Bella Vista – Itapúa
							</a>
						</div>

						<div className="distribuitor-information-block">
							<FontAwesomeIcon icon={ faPhone } />
							<p>0767 240 606</p>
						</div>
					</Accordion.Body>
				</Accordion.Item>

				<Accordion.Item eventKey="7">
					<Accordion.Header className="contact-acc-header">La Saeta - CDE</Accordion.Header>
					<Accordion.Body>
						<div className="distribuitor-information-block">
							<FontAwesomeIcon icon={ faLocationDot } />
							<a target="_blank" href="https://goo.gl/maps/hUPJiwA8XEEA88dF8">
								Sucursal Ciudad del Este Avda. Juan E Oleari, km 8 Barrio San Isidro – Alto Paraná
							</a>
						</div>

						<div className="distribuitor-information-block">
							<FontAwesomeIcon icon={ faPhone } />
							<p>0985 239 699</p>
						</div>
					</Accordion.Body>
				</Accordion.Item>

				<Accordion.Item eventKey="8">
					<Accordion.Header className="contact-acc-header">Comercial 40</Accordion.Header>
					<Accordion.Body>
						<div className="distribuitor-information-block">
							<FontAwesomeIcon icon={ faLocationDot } />
							<a target="_blank" href="https://goo.gl/maps/i9GwjM9B4uTGQ1427">
								Calle Padre Queiroz Candia c/ Aquidaban Concepción – Concepción
							</a>
						</div>

						<div className="distribuitor-information-block">
							<FontAwesomeIcon icon={ faPhone } />
							<p>0331 240 337</p>
						</div>
					</Accordion.Body>
				</Accordion.Item>

			</Accordion>
		</>
	)
}

export default ContactAccordion;
