import NewsItem from './NewsItem';

const NewsGrid = ({ news, type }) => {
    return (
        <>
            { news && (
                news.filter(n => n.type === type).map((newsData, key) => (
                    <NewsItem key={key} item={newsData} type={type} />
                ))

            )}
        </>
    )
}

export default NewsGrid;
