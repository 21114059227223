import React from "react";
import { FacebookShareButton,
	TwitterShareButton,
	WhatsappShareButton,
	FacebookIcon,
	WhatsappIcon,
	TwitterIcon
} from "react-share";

function ShareButtons({ url }) {
	return (
		<>
			<FacebookShareButton url={ url }>
				<FacebookIcon size={ 32 } round />
			</FacebookShareButton>

			<TwitterShareButton url={ url }>
				<TwitterIcon size= { 32 } round />
			</TwitterShareButton>

			<WhatsappShareButton url={ url }>
				<WhatsappIcon size= { 32 } round />
			</WhatsappShareButton>
		</>
	)
}

export default ShareButtons;
